import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import Chart from 'vue2-frappe'
Vue.use(Chart)

import VueChartkick from 'vue-chartkick'
// import annotationPlugin from 'chartjs-plugin-annotation';
import CharT from 'chart.js'

// CharT.register(annotationPlugin);
Vue.use(VueChartkick, { adapter: CharT })

import numFormat from 'vue-filter-number-format';

Vue.filter('nbr', numFormat);

//vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import axios from 'axios'
import VueAxios from 'vue-axios'

import { getAccessToken } from './utils/auth';

axios.interceptors.request.use(function (config) {
  // console.log('the access token: ' , getAccessToken());
  // if (getAccessToken() != null) {
  //   config.headers.Authorization = `Bearer ${getAccessToken()}`;
  // }

  return config;
}, function (err) {
  return Promise.reject(err);
});

//var instance = axios.create();
// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  if (error && error.message && error.message == "Network Error" && !error.response) {
    window.location = '/error';
    return Promise.reject(error);
  }

  // Do something with response error
  if (error && (error.response.status + "").startsWith("50")) {
    window.location = '/error';
    return Promise.reject(error);
  }
  if (400 == error.response.status) {
    window.location = '/';
  } else if ([401, 403].includes(error.response.status)) {
    localStorage.clear();
    sessionStorage.clear();
    store.commit("SET_JWT", "");
    window.location = '/';
  } else {
    return Promise.reject(error);
  }
});
//Vue.use(VueAxios, instance)// axios)

//progress bar
import CripLoading from "crip-vue-loading"


// Install component in to Vue instance and inject in to axios.
//Vue.use(CripLoading, {instance})
Vue.use(CripLoading, {
  axios: axios, 
  color:"#45b2d3", 
  height:"3px", 
  applyOnRouter:false })

Vue.use(VueAxios, axios)

//vue analytics
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'UA-21173516-2',
  router
})

import VueAppInsights from 'vue-application-insights'
const APP_INSIGHTS = process.env.VUE_APP_APP_INSIGHTS;

const enableLoging = process.env.VUE_APP_Enable_Loging;


//if (enableLoging == true)
  Vue.use(VueAppInsights, {
    id: APP_INSIGHTS,
    router
  })


import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

const APP_BUGSNAG = process.env.VUE_APP_BUGSNAG;

if (enableLoging == true) {
  const bugsnagClient = bugsnag(APP_BUGSNAG)
  bugsnagClient.use(bugsnagVue, Vue)
}



import moment from 'moment'
moment.locale('ro');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
