<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns is-centered has-text-centered">
                    <div class="column">
                        <p class="title is-size-3 has-text-white">
                            <!-- Ups... nu ne asteptam la asta! -->
                            <!--              „Succesul nu este final, eșecul nu este fatal: curajul de a continua este ceea ce contează.”-->
                            <!-- Oops! Ceva nu a funcționat cum trebuie! Echipa noastră lucrează deja la rezolvarea problemei. Încercați din nou mai târziu. Îți mulțumim pentru răbdare! -->
                            Oops! Ceva nu a funcționat cum trebuie! <br />
                            Încercați din nou mai târziu.
                            <!-- Îți mulțumim pentru răbdare! -->
                        </p>
                        <h2 class="subtitle has-text-white">
                            <!-- Winston Churchill -->
                        </h2>
                        <div class="column">
                            <router-link
                                class="button is-large1 is-primary"
                                to="/logout"
                            >
                                <<< Login</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hero-foot">
            <!-- <div class="has-text-centered">// Scroll down arrow here?</div> -->
        </div>
    </section>
    <!--

  <div class="columns bg is-vcentered is-centered has-text-centered bd-index-fullscreen ">
    <div class="column">
      <div class="columns is-centered">
        <div class="column is-half">
          <p class="title is-size-3 has-text-white">Ups... nu ne asteptam la asta!</p>

          <div>
            <router-link class="button is-large1 is-primary" to="/">Start</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>-->

    <!-- <div class="columns hero is-fullheight is-fullwidth1 is-centered is-vcentered has-text-centered ">
            <div class="column ">
                                    <p class="title is-size-3">
                                    „Succesul nu este final, eșecul nu este fatal: curajul de a continua este ceea ce contează.”
                                    </p>
                                    <h2 class="subtitle">
                                    Winston Churchill
                                    </h2>
                                    <div>
                                        <router-link class="button is-large1 is-primary" to="/">  hai sa ne intoarcem</router-link>

                                    </div>

            </div>
  </div>-->
    <!--
   <div class="hero is-fullheight">



        <div class="container is-centered">





            <div class="about  has-text-centered">
            <section class="hero">
                <div class="hero-body">
                <div class="container">

                    <p class="title is-size-3">
                    „Succesul nu este final, eșecul nu este fatal: curajul de a continua este ceea ce contează.”
                    </p>
                    <h2 class="subtitle">
                    Winston Churchill
                    </h2>
                    <div>
                        <router-link class="button is-large1 is-primary" to="/"> <<-- Hai sa ne intoarcem</router-link>

                    </div>
                    <br>
                      <img src="/img/error.jpeg" alt="" srcset="">

                </div>
                </div>
            </section>
        </div>
        </div>
  </div>-->
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "Error",
    methods: {
        returnToRoot() {
            this.$router.push("/logout");
        },
    },
};
</script>

<style scoped>
.hero {
    background-image: url(/img/error.jpeg);
    background-size: cover;
}
</style>
