<template>
    <section class="is-fullheight">
        <!-- START NAV -->
        <nav class="navbar is-white has-background-white-bis">
            <div class="container">
                <div class="navbar-brand">
                    <router-link class="navbar-item brand-text" to="/">
                        <span class="icon is-large">
                            <i class="fas fa-gas-pump fa-2x"></i>
                        </span>
                        <span>combu.ro</span>
                    </router-link>
                    <div class="navbar-burger burger" data-target="navMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div id="navMenu" class="navbar-menu">
                    <div class="navbar-end">
                        <!-- <ul class="menu-list">
                        <li><router-link   class="is-hidden-desktop"
                                v-for="m in menuItems" :key="m.path"
                                :to="m.path">{{m.title}}</router-link></li>
                    </ul>
            -->
                        <!-- <router-link v-for="m in menuItems" :key="m.path" :to="m.path" class="navbar-item is-hidden-desktop">{{m.title}}</router-link> -->
                        <router-link
                            class="navbar-item is-hidden-desktop"
                            v-for="m in menuItems"
                            :key="m.path"
                            :to="m.path"
                            >{{ m.title }}</router-link
                        >
                        <router-link to="/logout" class="navbar-item"
                            >logout</router-link
                        >
                        <!-- <a href="/.auth/logout" class="navbar-item" >logout</a> -->
                        <!-- <router-link  to="/.auth/logout" class="navbar-item">Logout 1</router-link> -->
                    </div>
                </div>
            </div>
        </nav>
        <!-- END NAV -->
        <div class="container">
            <div class="columns">
                <div class="column is-2">
                    <aside class="menu is-hidden-mobile mt-3">
                        <p class="menu-label">General</p>
                        <ul class="menu-list">
                            <li v-for="m in menuItems" :key="m.path">
                                <router-link :to="m.path">{{
                                    m.title
                                }}</router-link>
                            </li>
                        </ul>
                        <p class="menu-label">Administrare</p>
                        <ul class="menu-list">
                            <!--<li>-->
                            <!--    <router-link to="/settings">Setari</router-link>-->
                            <!--</li>-->
                            <!-- <li>
                               <router-link v-show="isAdmin" to="/accounts">Accounts</router-link>
                            </li> -->
                            <li>
                                <a href="/docs/index.html" target="_blank"
                                    >Manual</a
                                >
                            </li>
                            <li>
                                <a
                                    href="https://combu.freshdesk.com"
                                    target="_blank"
                                    >Suport</a
                                >
                            </li>
                            <li>
                                <router-link to="/contact">Contact</router-link>
                            </li>
                            <li>
                                <router-link to="/logout">Logout</router-link>
                            </li>
                        </ul>
                        <p class="menu-label" hidden>Transactions</p>
                        <ul class="menu-list" hidden>
                            <li>
                                <a>Payments</a>
                            </li>
                            <li>
                                <a>Transfers</a>
                            </li>
                            <li>
                                <a>Balance</a>
                            </li>
                        </ul>
                    </aside>
                </div>
                <div class="column is-10">
                    <router-view class="flexy1"></router-view>
                </div>
            </div>
        </div>

        <footer class="new-footer is-hidden-mobile has-background-white-bis">
            <div class="has-text-centered1 container tabs is-centered">
                <ul class="has-text-centered">
                    <li>
                        <a>{{ new Date().getFullYear() }} &copy; not yet!</a>
                    </li>
                    <li>
                        <a href="https://blog.combu.ro" target="_blank"
                            >the Blog</a
                        >
                    </li>
                </ul>
            </div>
        </footer>
    </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isLoggedIn, login, logout } from "@/utils/auth";
import { initMenu } from "@/utils/burger";


export default {
    computed: { ...mapGetters(["isAuthenticated", "isAdmin"]) },
    data() {
        return {
            menuItems: [
                { path: "dashboard", title: "Dashboard" },
                { path: "calendar", title: "Calendar" },
                { path: "vehicles", title: "Autovehicule" },
                { path: "fuels", title: "Carburant" },
                { path: "insurances", title: "Asigurari" },
                { path: "expenses", title: "Cheltuieli" },
                // { path: "services", title: "Service" },
            ],
        };
    },
    methods: {
        ...mapActions(["loadMenu"]),
        isLoggedIn: async () => await isLoggedIn(),
    },
    mounted() {
        initMenu();
        this.loadMenu();   
        document.title = process.env.VUE_APP_TITLE;
    },
};
</script>

<style>
.mt-3 {
    margin-top: 4em;
}

.new-footer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 10;
}
</style>
