<template>
  <div class="flexy1">
    <div class="container has-text-centered">
      <h2 class="is-size-3 is-loading">
        <b-loading  :active="true"></b-loading>
        .... autentificare in curs ....
      </h2>
      
    </div>
  </div>
</template>
<script>
import { setIdToken, setAccessToken } from "../utils/auth";

import store from "../store";

export default {
  name: "",
  mounted() {
    this.$nextTick(async () => {
      var resp = await fetch("/.auth/me");
      var json = await resp.json();
      store.dispatch("loadInitial");

       window.location.href = "/dashboard";
    });
  }
};
</script>


