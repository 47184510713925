<template>
  <div>
    <section class="info-tiles">
      <div class="tile is-ancestor has-text-centered">
        <div v-show="totalVehicles > 0" class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">{{totalVehicles}}</p>
            <p class="subtitle">Vehicule</p>
          </article>
        </div>
        <div v-show="showInsuranes" class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">
              <b-tooltip
                v-show="insurancesAboutToExpire > 0"
                label="Asigurari care vor expira in curand"
              >
                <span class="tag is-warning is-size-6">{{insurancesAboutToExpire}}</span>
              </b-tooltip>&nbsp;
              <b-tooltip v-show="insurancesExpired > 0" label="Asigurari expirate">
                <span class="tag is-danger is-size-6">{{insurancesExpired}}</span>
              </b-tooltip>
            </p>
            <p class="subtitle">
              Asigurari
              <sup>
                <b-icon icon="baseline-contact_support" size="is-small"></b-icon>
              </sup>
            </p>
          </article>
        </div>
        <div v-show="totalFuels > 0" class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">
              <b-tooltip label="Suma tuturor bonurilor de carburant">{{totalFuels | nbr}} lei</b-tooltip>
            </p>
            <p class="subtitle">Combustibil</p>
          </article>
        </div>
        <div v-show="totalExpenses > 0" class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">
              <b-tooltip label="Suma tuturor cheltuielilor inclusiv bonuri de carburant">{{totalExpenses | nbr}} lei</b-tooltip>
            </p>
            <p class="subtitle">Cheltuieli Totale</p>
          </article>
        </div>
        <!-- <div v-show="fuels > 0" class="tile is-parent">
                            <article class="tile is-child box">
                                <p class="title">{{fuels |nbr}}</p>
                                <p class="subtitle">Combustibil</p>                                
                            </article>
        </div>-->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Overview",
  computed: {
    ...mapState([
      "insurancesExpired",
      "insurancesAboutToExpire",
      "totalVehicles",
      "totalInsurances",
      "totalFuels",
      "totalExpenses"
    ]),
    showInsuranes() {
      return (
        this.insurancesAboutToExpire &&
        this.insurancesExpired &&
        (this.insurancesAboutToExpire > 0 || this.insurancesExpired > 0)
      );
    }
  }
};
</script>

