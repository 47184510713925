<template>
  <div class="flexy comlumns">
    <div class=" is-centered column is-vcentered is-8
is-offset-2 is-narrow">
      <div class=" has-text-centered">
        <section class="hero">
          <div class="hero-body">
            <div class="container">
              <h1 class="title">Formular de contact</h1>
              <h2 class="subtitle">
                <br>
                <br v-show="!messageSent">Pentru orice fel de informatii legate de combu.ro si nu numai, va rugam sa utilizati formularul de mai jos:
              </h2>

              <section v-show="!messageSent && !isError">
                <form @submit.prevent="send">
                  <div></div>
                  <div class="columns is-8">
                    <div class="column">
                      <b-field >
                        <b-input
                          placeholder="nume"                          
                          type="text"
                          v-model="message.name"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input placeholder="email" type="email" v-model="message.email" required></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field>
                        <b-input
                          type="textarea"
                          minlength="3"
                          maxlength="2000"
                          placeholder="detalii"
                          v-model="message.text"
                          required
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="column">
                    <button
                      v-bind:class="{'is-loading' : isSending == true}"
                      class="button is-primary is-medium">
                      <span class="icon is-small">
                        <i class="fa fa-envelope"></i>
                      </span>
                      <span>Trimite</span>
                    </button>
                  </div>
                </form>
              </section>

              <div class="notification is-primary is-size-3" v-show="messageSent">
                <button class="delete" @click="messageSent=false"></button>
                <br>Mesajul a fost trimis cu success!
                <br>
                <br>
                <router-link to="/" class="button">Inapoi la prima pagina</router-link>
              </div>

              <div class="notification is-danger is-size-3" v-show="isError">
                <br>Mesajul nu a putut fi trimis!
                <br>Va rugam incercati mai tarziu.
                <br>
                <br>
                <router-link to="/" class="button">Inapoi la prima pagina</router-link>
              </div>

              <h2 class="is-size-3"></h2>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isSending: false,
      isError: false,
      messageSent: false,
      message: {
        name: "",
        email: "",
        text: ""
      }
    };
  },
  methods: {
    ...mapActions(["sendContact"]),
    send() {
      if (this.message.name && this.message.email && this.message.text) {
        (this.isSending = true),
          this.sendContact(this.message)
            .then(resp => {
              this.message = { name: "", email: "", text: "" };
              this.messageSent = true;
              this.isSending = false;
            })
            .catch(err => {
              this.isError = true;
              this.messageSent = false;
              console.err("error sending contact: ", err);
            });
      }
    }
  }
};
</script>

