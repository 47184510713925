// The following code is based off a toggle menu by @Bradcomp
// source: https://gist.github.com/Bradcomp/a9ef2ef322a8e8017443b626208999c1


export const initMenu = () => {
    try{
        
        var burger = document.querySelector('.burger');
        var menu = document.querySelector('#'+burger.dataset.target);
        var burgerHandler = function() {
            burger.classList.toggle('is-active');
            menu.classList.toggle('is-active');
        };
        burger.removeEventListener("click", burgerHandler);
        burger.addEventListener('click',burgerHandler );
    }catch(e){}   
 
};

export const hideMenu = () =>{
     try{
        var burger = document.querySelector('.burger');
        var menu = document.querySelector('#'+burger.dataset.target);
        burger.classList.remove('is-active');
        menu.classList.remove('is-active');
     }catch(e){

     }
   
}