<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half has-text-centered">

            <h2 v-show="!isError" class="is-size-3 is-loading">
              <b-loading :active="isLoading"></b-loading>
              .... autentificare in curs ....
            </h2>

            <b-notification
            v-show="isError"
            type="is-danger"
            has-icon
            icon-size="is-large"
            :closable="false"
            aria-close-label="Close notification"
            role="alert">
            <h2  class="is-size-3 is-loading">
                <b-loading :active="isLoading"></b-loading>
                Autentificarea esuata!
              </h2>

              <h3>
                <router-link to="/" class="button">
                  Login!</router-link
                >
              </h3>
        </b-notification>



          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot">
      <!-- <div class="has-text-centered">// Scroll down arrow here?</div> -->
    </div>
  </section>

  <!-- 

    <div class="container has-text-centered is-flex is-justify-content-center is-align-items-center" style="height: 100vh;">
  
      <div class="container" style="position: relative; height: 100vh;margin:auto">
  <div style="position: absolute; top: 40%; transform: translateY(-40%);">
    Your Content
  
    <div class="container has-text-centered">
     
     <div>
      <h2 v-show="!isError" class="is-size-3 is-loading">
        <b-loading :active="isLoading"></b-loading>
        .... autentificare in curs ....
      </h2>
      



      <h2 v-show="isError" class="is-size-3 is-loading">
        <b-loading :active="isLoading"></b-loading>
        Autentificarea esuata!

      </h2>

      <h3> 
        <router-link to="/" class="button is-primary"> Inapoi la autentificare!</router-link>  
      </h3>   
      
    </div>
  </div>
</div>

    </div>
  </div> -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { setIdToken, setAccessToken } from "../utils/auth";

import store from "../store";

export default {
  name: "",
  data() {
    return { isLoading: false, isError: false };
  },
  methods: {
    ...mapActions(["authenticateByEmailAndToken"]),
    async getTheJwt(email, token) {
      var tkn = await this.authenticateByEmailAndToken({
        email: email,
        token: token,
      });
      // console.log("Received token is:", tkn);
      // console.dir(this.$router);
      return !!tkn;
    },
  },
  //
  async mounted() {
    let email = this.$route.query.email;
    let token = this.$route.query.token;
    console.log(email, token);
    // await this.getTheJwt(email, token);
    // var resp  = await fetch()
    const that = this;
    this.$nextTick(async () => {
      // console.log("Inside nextTick..")
      let email = that.$route.query.email;
      let token = that.$route.query.token;
      console.log(email, token);
      const isValidToken = await that.getTheJwt(email, token);
      if (isValidToken) that.$router.push({ name: "dashboard" });
      else this.isError = true;
      this.isLoading = false;
    });
  },
};
</script>
