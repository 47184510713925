import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Dashboard from "./views/Dashboard.vue";
import Fuels from "./views/Fuels.vue";
import Vehicles from "./views/Vehicles.vue";
import Error from "@/components/Error";
import Callback from "@/components/callback";
import Insurances from "./views/Insurances";
import Contact from "@/views/Contact";
import Expenses from "@/views/Expenses";
import Services from "@/views/Services";
import Calendar from "@/views/Calendar";
import Drivers from "@/views/Drivers";
import Settings from "@/views/Settings";
import Accounts from "@/views/Accounts";

import Landing from "@/views/Landing.vue";
import Admin from "@/views/Admin.vue";
import Authenticate from "@/components/Authenticate";

import { requireAuth, logout } from "./utils/auth";

Vue.use(Router);

import store from "./store";

import { hideMenu } from "./utils/burger";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Landing,
      children: [
        {
          path: "/",
          component: Home,
        },
        {
          path: "/contact",
          name: "contactLanding",
          component: Contact,
        },
      ],
    },
    {
      path: "/",
      component: Admin,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          beforeEnter: requireAuth,
          component: Dashboard,
        },
        {
          path: "/fuels",
          name: "fuels",
          beforeEnter: requireAuth,
          component: Fuels,
        },
        {
          path: "/vehicles",
          name: "vehicles",
          component: Vehicles,
          beforeEnter: requireAuth,
        },
        {
          path: "/insurances",
          name: "insurances",
          component: Insurances,
          beforeEnter: requireAuth,
        },
        {
          path: "/expenses",
          name: "expenses",
          component: Expenses,
          beforeEnter: requireAuth,
        },
        {
          path: "/services",
          name: "services",
          component: Services,
          beforeEnter: requireAuth,
        },
        {
          path: "/calendar",
          component: Calendar,
        },
        {
          path: "/drivers",
          component: Drivers,
        },
        {
          path: "/settings",
          component: Settings,
        },
        {
          path: "/accounts",
          component: Accounts,
        },
        {
          path: "/contact",
          name: "contactAdmin",
          component: Contact,
        },
        {
          path: "/logout",
          async beforeEnter(to, from, next) {
            // store.dispatch("logout");
            await logout();
            //next('/');
          },
        },
        {
          path: "/contact-new",
          name: "contact-new",
          component: Contact,
        },
      ],
    },
    {
      path: "/home",
      name: "home",
      component: Home,
    },

    ,
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
    },
    {
      path: "/callback",
      component: Callback,
    },
    {
      path: "/authenticate",
      component: Authenticate,
    },
    {
      path: "/error",
      name: "error",
      component: Error,
    },
    { path: "*", redirect: "/" },
  ],
});

// router.beforeEach((to, from, next) => {
//   hideMenu();
//   next();
// });

router.afterEach((to, from) => hideMenu());

export default router;
