<template>
    <div class="flexy">
        <section class="hero">
            <div class="hero-body">
                <div class="level">
                    <div class="level-left">
                        <h1 class="title">Autovehicule</h1>
                    </div>
                    <div class="level-right">
                        <button class="button is-primary" @click="addVehicle">
                            <span class="icon is-small">
                                <i class="fa fa-plus-circle"></i>
                            </span>
                            <span>Adauga</span>
                        </button>
                    </div>
                </div>
                <hr class="is-hidden-mobile" />
            </div>
        </section>
        <div class>
            <b-field class="is-pulled-right is-clearfix">
                <b-input
                    placeholder="nr auto ..."
                    v-model="query"
                    type="input"
                    icon="magnify"
                    @keyup.native.enter="search"
                ></b-input>
                <p class="control">
                    <button
                        class="button is-primary is-outlined"
                        @click="search"
                    >
                        Cauta
                    </button>
                </p>
            </b-field>

            <div class="is-clearfix"></div>

            <div class="table-container" v-show="!isLoading">
                <!-- && vehiclesList.length > 0 "> -->
                <b-table
                    :data="vehiclesList"
                    :bordered="true"
                    :striped="true"
                    :narrowed="false"
                    :hoverable="true"
                    :loading="isLoading"
                >
                    
                 
            
                        <!-- <b-table-column field="id" label="ID" width="40" numeric>
                            {{ props.row.id }}
            </b-table-column>-->
                        <!-- <b-table-column>
              {{props}}
            </b-table-column> -->


<template bordered>
                        <b-table-column
                            field="name"
                            width="130"
                            label="Nr. auto"
                            
                             v-slot="props"
                            >{{ props.row.name }} </b-table-column> 

                        <b-table-column field="model" label="Model" v-slot="props">{{
                            props.row.model
                        }}</b-table-column>

                        <b-table-column field="itp" label="ITP" v-slot="props">{{
                            props.row.itp
                        }}</b-table-column>

                        <b-table-column field="rca" label="RCA" v-slot="props">{{
                            props.row.rca
                        }}</b-table-column>

                        <b-table-column
                            field="fuelTypeName"
                            label="Combustibil"
                            v-slot="props"
                            >{{ props.row.fuelTypeName }}</b-table-column
                        >
                        <b-table-column
                            field="totalSpent"
                            label="Costuri"
                            v-slot="props"
                            >{{ props.row.totalSpent | nbr }} lei</b-table-column
                            >
                        <b-table-column label :centered="true" width="140" v-slot="props">
                            <button
                                class="button is-small is-rounded"
                                @click="edit(props.row)"
                            >
                                <span class="icon is-small">
                                    <i class="fa fa-edit"></i>
                                </span>
                            </button>
                            &nbsp;
                            <button
                                class="button is-small is-rounded"
                                @click="del(props.row)"
                            >
                                <span class="icon is-small has-text-danger">
                                    <i class="fa fa-trash"></i>
                                </span>
                            </button>
                        </b-table-column> 
                    </template>
                    <template #empty>
                        <div class="has-text-centered">
                            <h1 class="is-size-5">
                                Nu am gasit nici un autovehicul.
                            </h1>
                            <div>
                                <button
                                    class="button is-text"
                                    @click="addVehicle"
                                >
                                    Adauga un autovehicul
                                </button>
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>

            <!-- <div>
        <div v-show="vehiclesList.length == 0" class="has-text-centered mt_50">
          <h1 class="is-size-5">Nu am gasit nici un autovehicul.</h1>
          <div>
            <button class="button is-text" @click="addVehicle">Adauga un autovehicul</button>
          </div>
        </div>
      </div> -->

            <b-pagination
                v-show="!isLoading && vehiclesList.length > 0"
                :total="vehicles.total"
                :current.sync="page"
                :simple="false"
                :rounded="false"
                :per-page="vehicles.perPage"
                @change="doPaginate"
            ></b-pagination>
            <loading-info />
        </div>
    </div>
</template>

<script>
import VehicleForm from "@/components/VehicleForm";
import LoadingInfo from "@/components/LoadingInfo";

import { EventBus } from "@/event-bus.js";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "dashboard",
    components: { LoadingInfo },
    data() {
        return { query: "", page: 1 };
    },
    computed: {
        ...mapGetters(["vehicles", "isLoading"]),
        vehiclesList() {
            return this.vehicles.items || [];
        },
    },

    methods: {
        ...mapActions(["loadVehicles", "delVehicle", "loadDashboard"]),
        search() {
            this.page = 1;
            this.doPaginate();
        },
        addVehicle: function () {
            this.$buefy.modal.open({
                parent: this,
                component: VehicleForm,
                hasModalCard: true,
                canCancel: false,
                props: { reload: this.doPaginate },
            });
        },
        del(v) {
            this.$buefy.dialog.confirm({
                message: `Sigur stergem: ${v.name} ?`,
                onConfirm: () => {
                    this.delVehicle(v.id)
                        .then(() => {
                            //this.loadVehicles();
                            this.loadDashboard();
                            this.$toast.open("Autovehicul sters!");
                        })
                        .catch((err) => console.log(err));
                },
            });
        },
        edit(v) {
            this.$buefy.modal.open({
                parent: this,
                component: VehicleForm,
                hasModalCard: true,
                canCancel: false,
                props: { vehicleId: v.id, reload: this.doPaginate },
            });
        },
        doPaginate(page) {
            page = page || 1;
            this.page = page;
            this.loadVehicles({ page: page, query: this.query });
        },
    },
    mounted() {
        // EventBus.$on("reload-data", () => {
        //   this.query = "";
        //   console.log("event caught!! ", new Date());
        //   //  this.search()
        // });
    },
    created() {
        this.loadVehicles();
    },
};
</script>
