var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"modal-card"},[_vm._m(0),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"field is-horizontal"},[_vm._m(1),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field is-narrow"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"select is-fullwidth"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.expense.expenseType),expression:"expense.expenseType"}],attrs:{"autofocus":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.expense, "expenseType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Alege tipul")]),_vm._l((_vm.eTypes),function(t){return _c('option',{key:t.name,domProps:{"value":t.name}},[_vm._v("\n                                            "+_vm._s(t.name)+"\n                                        ")])})],2)]),(
                                        _vm.$v.expense.expenseType.$dirty &&
                                        !_vm.$v.expense.expenseType.required
                                    )?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                                    Alege tipul\n                                ")]):_vm._e()])])])]),_c('div',{staticClass:"field is-horizontal"},[_vm._m(2),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.expense.provider),expression:"expense.provider"}],staticClass:"input",attrs:{"type":"text","placeholder":"Furnizor"},domProps:{"value":(_vm.expense.provider)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.expense, "provider", $event.target.value)}}})]),(
                                    _vm.$v.expense.provider.$dirty &&
                                    !_vm.$v.expense.provider.required
                                )?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                                Specifica furnizorul.\n                            ")]):_vm._e()])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expense.expenseType != 'Numerar'),expression:"expense.expenseType != 'Numerar'"}],staticClass:"field is-horizontal"},[_vm._m(3),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.expense.number),expression:"expense.number"}],staticClass:"input",attrs:{"type":"text","placeholder":"Nr. document"},domProps:{"value":(_vm.expense.number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.expense, "number", $event.target.value)}}})]),(
                                    _vm.$v.expense.number.$dirty &&
                                    !_vm.$v.expense.number.nrIsReq
                                )?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                                Specifica numarul documentului.\n                            ")]):_vm._e()])])]),_c('div',{staticClass:"field is-horizontal"},[_vm._m(4),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control"},[_c('b-datepicker',{attrs:{"placeholder":"Data bon","date-formatter":_vm.dateFormatter,"icon":"calendar-today"},model:{value:(_vm.expense.date),callback:function ($$v) {_vm.$set(_vm.expense, "date", $$v)},expression:"expense.date"}})],1)])])]),_c('div',{staticClass:"field is-horizontal"},[_vm._m(5),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field is-narrow"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"select is-fullwidth"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.expense.vehicleId),expression:"expense.vehicleId"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.expense, "vehicleId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("\n                                            Alege autovehicul\n                                        ")]),_vm._l((_vm.vehiclesList),function(v){return _c('option',{key:v.id,domProps:{"value":v.id}},[_vm._v("\n                                            "+_vm._s(v.name)+"\n                                        ")])})],2)]),(
                                        _vm.$v.expense.vehicleId.$dirty &&
                                        !_vm.$v.expense.vehicleId.required
                                    )?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                                    Alege autovehiculul\n                                ")]):_vm._e()])])])]),_c('div',{staticClass:"field is-horizontal"},[_vm._m(6),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.expense.amount),expression:"expense.amount"}],staticClass:"input",attrs:{"type":"number","step":"0.01","placeholder":"Total cu tva"},domProps:{"value":(_vm.expense.amount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.expense, "amount", $event.target.value)}}})]),(
                                    _vm.$v.expense.amount.$dirty &&
                                    !_vm.$v.expense.amount.required
                                )?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                                Specifica total cu tva.\n                            ")]):_vm._e()])])]),_c('div',{staticClass:"field is-horizontal"},[_vm._m(7),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.expense.details),expression:"expense.details"}],staticClass:"textarea",attrs:{"placeholder":"informatii suplimentare"},domProps:{"value":(_vm.expense.details)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.expense, "details", $event.target.value)}}})])])])])]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("\n                    Renunta\n                ")]),_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.isSaving == true }},[_vm._m(8),_c('span',[_vm._v("Salveaza")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Detalii")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-label is-normal"},[_c('label',{staticClass:"label"},[_vm._v("Tip document")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-label is-normal"},[_c('label',{staticClass:"label"},[_vm._v("Furnizor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-label is-normal"},[_c('label',{staticClass:"label"},[_vm._v("Nr. Document")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-label is-normal"},[_c('label',{staticClass:"label"},[_vm._v("Data")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-label is-normal"},[_c('label',{staticClass:"label"},[_vm._v("Autovehicul")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-label is-normal"},[_c('label',{staticClass:"label"},[_vm._v("Total platit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-label is-normal"},[_c('label',{staticClass:"label"},[_vm._v("Detalii")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fa fa-save"})])
}]

export { render, staticRenderFns }