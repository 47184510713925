<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card" v-show="!isLoading">
        <header class="modal-card-head">
          <p class="modal-card-title">Detalii bon carburant</p>
        </header>
        <section class="modal-card-body">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Data</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <b-datepicker
                    placeholder="Data bon"
                    v-model="fuel.date"
                    :date-formatter="dateFormatter"
                    icon="calendar-today"
                  ></b-datepicker>
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Autovehicul</label>
            </div>
            <div class="field-body">
              <div class="field is-narrow">
                <div class="control">
                  <div class="select is-fullwidth">
                    <select autofocus v-model="fuel.vehicleId">
                      <option value>Alege autovehicul</option>
                      <option v-for="v in vehiclesList" :key="v.id" :value="v.id">{{v.name}}</option>
                    </select>
                  </div>
                  <p
                    class="help is-danger"
                    v-if="$v.fuel.vehicleId.$dirty && !$v.fuel.vehicleId.required"
                  >Alege autovehiculul</p>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Kms la bord</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" type="number" v-model="fuel.kms" placeholder="Km la bord">
                </p>
                <p
                  class="help is-danger"
                  v-if="$v.fuel.kms.$dirty && !$v.fuel.kms.required"
                >Specifica numarul de km la bord.</p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Total Litri</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="number"
                    step="0.01" 
                    v-model="fuel.litres"
                    placeholder="Total litri alimentati"
                  >
                </p>
                <p
                  class="help is-danger"
                  v-if="$v.fuel.litres.$dirty && !$v.fuel.litres.required"
                >Specifica litrii alimentati.</p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Total bon</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="number"
                    step="0.01" 
                    v-model="fuel.price"
                    placeholder="Total platit"
                  >
                </p>
                <p
                  class="help is-danger"
                  v-if="$v.fuel.price.$dirty && !$v.fuel.price.required"
                >Specifica totalul de pe bon.</p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Alimentare partiala</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <b-switch v-model="fuel.isPartial"></b-switch>
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Observatii</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <textarea
                    class="textarea"
                    v-model="fuel.comments"
                    placeholder="informatii suplimentare"
                  ></textarea>
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$parent.close()">Renunta</button>
          <button class="button is-primary" v-bind:class="{'is-loading' : isSaving == true}">
            <span class="icon is-small">
              <i class="fa fa-save"></i>
            </span>
            <span>Salveaza</span>
          </button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { required } from "vuelidate/lib/validators";
import LoadingInfo from "@/components/LoadingInfo";

export default {
  name: "FuelForm",
  props: ["vehicleId", "fuelId", "reload"],
  components: { LoadingInfo },
  computed: {
    ...mapGetters(["vehiclesList", "isLoading"])
  },
  data: function() {
    return {
      isSaving: false,
      fuel: {
        vehicleId: "",
        date: new Date(),
        kms: null,
        litres: null,
        price: null,
        isPartial: false,
        comments: ""
      }
    };
  },
  validations: {
    fuel: {
      vehicleId: { required },
      kms: { required },
      litres: { required },
      price: { required }
    }
  },
  methods: {
    ...mapActions([
      "saveFuel",
      "loadFuels",
      "loadFuel",
      "loadInitialWithPromise"
    ]),
    dateFormatter(dt) {
      return dt.toLocaleDateString("ro-RO");
    },
    save() {
      //todo: validate
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSaving = !this.isSaving;
        this.saveFuel(this.fuel)
          .then(resp => {
            if (this.reload)
              this.reload();

            this.$parent.close();
            this.isSaving = !this.isSaving;
          })
          .catch(err => {
            console.error("error: " + err);
            this.isSaving = !this.isSaving;
          });
      }
    },
    load(id) {
      this.loadFuel(id)
        .then(resp => {
          this.fuel = resp.data;
          this.fuel.date = new Date(this.fuel.date);
        })
        .catch(err => console.log("error loading veh:", err));
    }
  },
  mounted() {
    var id = this.fuelId;
    this.loadInitialWithPromise().then(() => {
      if (this.vehicleId) this.fuel.vehicleId = this.vehicleId;
      if (id > 0) {
        this.load(id);
      }
    });
  }
};
</script>

