import decode from "jwt-decode";
import axios from "axios";
import Router from "vue-router";

import store from "@/store";

import jwtDecode from "jwt-decode";

var router = new Router({
  mode: "history",
});

async function getUserInfo() {
  const response = await fetch("/.auth/me");
  const payload = await response.json();
  const { clientPrincipal } = payload;
  return clientPrincipal;
}

export async function logout() {
  // clearIdToken();
  // clearAccessToken();
  await store.dispatch("logout");
  window.location.href = "/";
}

export async function isLoggedIn() {
  // let ui = await getUserInfo();
  // let authenticated = (ui != null && ui.userDetails != null);
  // return  authenticated;

  // get token if present
  const oldTkn = sessionStorage.getItem("combu-jwt");
  if (oldTkn && jwtDecode(oldTkn)) store.commit("SET_JWT", oldTkn);

  return !!store.getters.jwt;
}

export async function requireAuth(to, from, next) {
  if (!(await isLoggedIn())) {
    next({
      path: "/",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

function isTokenExpired(token) {
  try {
    if (!token) return;
    const decoded = jwtDecode(token);
    if (!decoded.exp) {
      return true;
    }

    // Token exp time is in seconds
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date < new Date();
  } catch (err) {
    console.log("Error decoding jwt!", err);
    return false;
  }
}

// var token = "your_jwt_token";
// console.log(isTokenExpired(token)); // prints true if the token is expired, false otherwise

// export function getIdToken() {
//   return localStorage.getItem(ID_TOKEN_KEY);
// }

// export function getAccessToken() {
//   //console.log('access token: ', localStorage.getItem(ACCESS_TOKEN_KEY) )
//   return localStorage.getItem(ACCESS_TOKEN_KEY);
// }

// function clearIdToken() {
//   localStorage.removeItem(ID_TOKEN_KEY);
// }

// function clearAccessToken() {
//   localStorage.removeItem(ACCESS_TOKEN_KEY);
// }

// // Helper function that will allow us to extract the access_token and id_token
// function getParameterByName(name) {
//   let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
//   if (match && match.length > 0)
//     return match && decodeURIComponent(match[1].replace(/\+/g, ' '));

//     return "";
// }

// // Get and store access_token in local storage
// export function setAccessToken() {
//   let accessToken = getParameterByName('access_token');
//   localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
// }

// // Get and store id_token in local storage
// export function setIdToken() {
//   let idToken = getParameterByName('id_token');
//   localStorage.setItem(ID_TOKEN_KEY, idToken);
// }

// function isTokenExpired(token) {
//   const expirationDate = getTokenExpirationDate(token);
//   return expirationDate < new Date();
// }

// function getTokenExpirationDate(encodedToken) {
//   const token = decode(encodedToken);
//   if (!token.exp) { return null; }

//   const date = new Date(0);
//   date.setUTCSeconds(token.exp);

//   return date;
// }
