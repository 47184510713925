<template>
    <div>
        <form @submit.prevent="save">
            <div class="modal-card" v-show="!isLoading">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalii</p>
                </header>
                <section class="modal-card-body">
                    <!-- TODO: add expense type -->
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Tip document</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-narrow">
                                <div class="control">
                                    <div class="select is-fullwidth">
                                        <select
                                            autofocus
                                            v-model="expense.expenseType"
                                        >
                                            <option value>Alege tipul</option>
                                            <option
                                                v-for="t in eTypes"
                                                :key="t.name"
                                                :value="t.name"
                                            >
                                                {{ t.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <p
                                        class="help is-danger"
                                        v-if="
                                            $v.expense.expenseType.$dirty &&
                                            !$v.expense.expenseType.required
                                        "
                                    >
                                        Alege tipul
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Furnizor</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input
                                        class="input"
                                        type="text"
                                        v-model="expense.provider"
                                        placeholder="Furnizor"
                                    />
                                </p>
                                <p
                                    class="help is-danger"
                                    v-if="
                                        $v.expense.provider.$dirty &&
                                        !$v.expense.provider.required
                                    "
                                >
                                    Specifica furnizorul.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="field is-horizontal"
                        v-show="expense.expenseType != 'Numerar'"
                    >
                        <div class="field-label is-normal">
                            <label class="label">Nr. Document</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input
                                        class="input"
                                        type="text"
                                        v-model="expense.number"
                                        placeholder="Nr. document"
                                    />
                                </p>
                                <p
                                    class="help is-danger"
                                    v-if="
                                        $v.expense.number.$dirty &&
                                        !$v.expense.number.nrIsReq
                                    "
                                >
                                    Specifica numarul documentului.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Data</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <b-datepicker
                                        placeholder="Data bon"
                                        v-model="expense.date"
                                        :date-formatter="dateFormatter"
                                        icon="calendar-today"
                                    ></b-datepicker>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Autovehicul</label>
                        </div>
                        <div class="field-body">
                            <div class="field is-narrow">
                                <div class="control">
                                    <div class="select is-fullwidth">
                                        <select v-model="expense.vehicleId">
                                            <option value>
                                                Alege autovehicul
                                            </option>
                                            <option
                                                v-for="v in vehiclesList"
                                                :key="v.id"
                                                :value="v.id"
                                            >
                                                {{ v.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <p
                                        class="help is-danger"
                                        v-if="
                                            $v.expense.vehicleId.$dirty &&
                                            !$v.expense.vehicleId.required
                                        "
                                    >
                                        Alege autovehiculul
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Total platit</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <input
                                        class="input"
                                        type="number"
                                        step="0.01"
                                        v-model="expense.amount"
                                        placeholder="Total cu tva"
                                    />
                                </p>
                                <p
                                    class="help is-danger"
                                    v-if="
                                        $v.expense.amount.$dirty &&
                                        !$v.expense.amount.required
                                    "
                                >
                                    Specifica total cu tva.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">Detalii</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <p class="control">
                                    <textarea
                                        class="textarea"
                                        v-model="expense.details"
                                        placeholder="informatii suplimentare"
                                    ></textarea>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button
                        class="button"
                        type="button"
                        @click="$parent.close()"
                    >
                        Renunta
                    </button>
                    <button
                        class="button is-primary"
                        v-bind:class="{ 'is-loading': isSaving == true }"
                    >
                        <span class="icon is-small">
                            <i class="fa fa-save"></i>
                        </span>
                        <span>Salveaza</span>
                    </button>
                </footer>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import LoadingInfo from "@/components/LoadingInfo";

const nrIsReq = (value, vm) => {
    if (vm) {
        var eq = vm.expenseType.toLowerCase() == "numerar";
        return eq ? true : (value || "").length > 0;
    }
    return true;
};

export default {
    name: "ExpenseForm",
    props: ["expenseId", "reload"],
    components: { LoadingInfo },
    computed: {
        ...mapState(["eTypes"]),
        ...mapGetters(["vehiclesList", "isLoading"]),
    },
    data: function () {
        return {
            query: "",
            isSaving: false,
            expense: {
                expenseType: "",
                number: "",
                date: new Date(),
                vehicleId: "",
                amount: null,
                details: "",
                provider: "",
            },
        };
    },
    validations: {
        expense: {
            number: { nrIsReq },
            expenseType: { required },
            amount: { required },
            vehicleId: { required },
            provider: { required },
        },
    },
    methods: {
        ...mapActions([
            "saveExpense",
            "loadDashboard",
            "loadExpenses",
            "loadExpense",
            "loadInitialWithPromise",
        ]),
        dateFormatter(dt) {
            return dt.toLocaleDateString("ro-RO");
            //return dt.toLocaleDateString('ro-RO');
        },
        save() {
            //todo: validate
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.isSaving = !this.isSaving;
                this.saveExpense(this.expense)
                    .then((resp) => {
                        this.reload();
                        // this.loadExpenses();
                        //  this.loadDashboard();
                        this.$parent.close();
                        this.isSaving = !this.isSaving;
                    })
                    .catch((err) => {
                        console.error("error: " + err);
                        this.isSaving = !this.isSaving;
                    });
            }
        },
        load(id) {
            this.loadExpense(id)
                .then((resp) => {
                    this.expense = resp.data;
                    this.expense.date = new Date(this.expense.date);
                })
                .catch((err) => console.log("error loading veh:", err));
        },
    },
    mounted() {
        this.loadInitialWithPromise().then(() => {
            var id = this.expenseId;
            if (this.vehicleId) this.expense.vehicleId = this.vehicleId;
            if (id > 0) {
                this.load(id);
            }
        });
    },
};
</script>
