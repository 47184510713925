<template>
    <div class="hero-body">
        <div class="container has-text-centered">
            <div class="home1">
                <div class="columns is-vcentered">
                    <div class="column is-6">
                        <!-- <figure class="image is-4by3 is-hidden"> -->
                            <!-- <img src="https://source.unsplash.com/collection/983341/1024x768" alt="Description">                                           -->
                            <!-- <img src="https://source.unsplash.com/collection/430968/800x600" alt="Description">  -->
                            <!-- <img :src="landingUrl" alt="combu landing" /> -->
                        <!-- </figure> -->


                        <b-carousel :repeat="true" :autoplay="true" indicator-custom :indicator-inside="false"    @click="switchGallery(true)">
                            <b-carousel-item v-for="(item, i) in 5" :key="i">
                                <b-image class="image" :src="getImgUrl(i)"></b-image>
                            </b-carousel-item>
                            <template #indicators="props">
                                <b-image class="al image" :src="getImgUrl(props.i)" :title="props.i"></b-image>
                            </template>
                        </b-carousel>

                    </div>
                    <div class="column is-5 is-offset-1">
                        <h1 class="title is-2">
                            <span class="icon is-large">
                                <i class="fas fa-gas-pump"></i>
                            </span>
                            <span>C O M B U s t i b i l</span>
                        </h1>

                        <h2 v-show="!emailSent" class="subtitle is-4">
                            Evidenta consum carburant si asigurari!
                            <!-- <p>simplu, rapid, online</p> -->
                        </h2>
                        <br />
                        <div v-show="!loggedIn" class="has-text-centered">
                            <form
                                v-show="!emailSent"
                                @submit.prevent="sendEmail"
                                class="level-item has-text-centered"
                            >
                                <div class="field is-grouped-multiline">
                                    <b-field>
                                        <!-- <div class="control is-expanded"> -->
                                        <input
                                            v-model="email"
                                            class="input is-large centered-input"
                                            type="email"
                                            required
                                            placeholder="Adresa ta de email"
                                        />
                                        <!-- </div> -->
                                        <div class="control">
                                            <b-button
                                                class="button is-primary is-large"
                                                :loading="isLoading"
                                                native-type="submit"
                                            >
                                                Login
                                            </b-button>
                                        </div>
                                    </b-field>
                                    <p class="help">
                                        Apăsând 'Login', veți primi un email cu
                                        link-ul pentru conectare, valabil pentru
                                        cont nou sau existent.
                                    </p>
                                </div>
                            </form>

                            <p
                                v-show="emailSent"
                                class="has-text-centered is-size-3"
                            >
                                Bine ai venit! Un email cu link-ul de
                                autentificare a fost trimis. Verifică-ți emailul
                                pentru acces în aplicația noastră.
                                <br />
                                <br />
                                <a
                                    class="button is-primary is-large"
                                    :href="emailUrl"
                                    >accesați {{ email.split("@").pop() }}</a
                                >
                            </p>

                            <a
                                class="button is-medium is-primary is-hidden"
                                v-show="!loggedIn"
                                @click="handleLogin"
                                >Autentificare (google)</a
                            >
                        </div>
                        <p class="has-text-centered">
                            <router-link
                                class="button is-medium is-primary"
                                v-show="loggedIn"
                                to="/dashboard"
                                >Dashboard</router-link
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//import Welcome from "@/components/Welcome.vue";
import Vue from "vue";

import { mapGetters, mapActions } from "vuex";
import { isLoggedIn, login, logout, isAuthenticated } from "../utils/auth";

export default {
    name: "home",
    data() {
        return {
            url: process.env.VUE_APP_REDIRECT,
            landingUrl: "/img/combu_landing.jpeg",
            loggedIn: false,
            emailSent: false,
            email: "",
            isLoading: false,
            gallery: false,
            images:[
                // "/img/combu_landing.jpeg",
                "/img/dashboard.png",
                "/img/insurances.png",
                "/img/expenses.png",
                "/img/calendar.png",
                "/img/email.png",
            ]

        };
    },
    computed: {
        emailUrl: function () {
            return this.email ? `https://${this.email.split("@").pop()}` : "";
        },
    },
    methods: {
        ...mapActions(["loadInitial", "loginByEmail"]),
        getImgUrl(value) {
            return this.images[value];
            // value += 50
            // return `https://picsum.photos/id/10${value}/1230/500`
        },
        switchGallery(value) {
            this.gallery = value
            if (value) {
                // return document.documentElement.classList.add('is-clipped')
            } else {
                // return document.documentElement.classList.remove('is-clipped')
            }
        },
        async sendEmail() {
            if (!this.email) return;
            this.isLoading = true;
            await this.loginByEmail(this.email);
            this.emailSent = true;
        },
        handleLogin() {
            window.location = `${window.location.href}.auth/login/google?post_login_redirect_uri=${window.location.href}callback`;
            //login();
        },
        getPic() {
            const image = new Image();
            image.src = "https://source.unsplash.com/collection/430968/800x600";

            image.onload = () => {
                this.landingUrl = image.src;
            };
        },
        async showAuthButton() {
            return (await this.isLoggedIn()) == true;
        },
    },
    async created() {
        this.getPic();
        this.loggedIn = await isLoggedIn();
    },
};
</script>

<style scoped>
h1.title {
    margin-bottom: 50px;
}

.centered-input {
    text-align: center;
}

/* carusel */
.is-active .al img {
    border: 1px solid #fff;
    filter: grayscale(0%);
}
.al img {
    border: 1px solid transparent;
    filter: grayscale(100%);
}



</style>
