<template>
    <section class="hero is-fullheight is-default is-bold">
        <div class="hero-head">
            <nav class="navbar">
                <div class="container">
                    <div class="navbar-brand">
                        <router-link class="navbar-item no-a-collor" to="/">
                            <span class="icon is-large">
                                <i class="fas fa-gas-pump fa-2x"></i>
                            </span>
                            <span> combu.ro</span>
                        </router-link>
                        <span
                            class="navbar-burger burger"
                            data-target="navbarMenu"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </div>

                    <p>{{ a }}</p>

                    <div id="navbarMenu" class="navbar-menu">
                        <div class="navbar-end">
                            <div class="tabs is-right">
                                <ul class="nav">
                                    <li class="nav-li" v-show="!loggedIn">
                                        <router-link to="/">acasa</router-link>
                                    </li>
                                    <li class="nav-li" v-show="loggedIn">
                                        <router-link to="/dashboard"
                                            >dashboard</router-link
                                        >
                                    </li>
                                    <li class="nav-li" v-show="loggedIn">
                                        <router-link to="/contact"
                                            >contact</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            v-show="!loggedIn"
                                            to="/contact"
                                            >contact</router-link
                                        >
                                    </li>
                                    <li>
                                        <a v-show="loggedIn" href="/logout"
                                            >logout</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <router-view class="flexy1" />
        <footer>
            <cookie-law
                theme="dark-lime"
                buttonText="ok"
                position="bottom"
                message="Acest site utilizează module cookie sau tehnologii similare pentru a vă asigura că beneficiați de cea mai bună experiență. Apăsând OK sau navigând pe acest website, sunteți de acord să permiți colectarea de informații prin cookie-uri sau tehnologii similare"
                transitionName="fade"
            ></cookie-law>
        </footer>
        <div class="hero-foot">
            <div class="container">
                <div class="tabs is-centered">
                    <ul>
                        <li>
                            <a
                                >{{ new Date().getFullYear() }} &copy; not
                                yet!</a
                            >
                        </li>
                        <li>
                            <a href="https://blog.combu.ro" target="_blank"
                                >the Blog</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isLoggedIn } from "@/utils/auth";
import CookieLaw from "vue-cookie-law";
import { initMenu } from "@/utils/burger";

export default {
    components: { CookieLaw },
    computed: { ...mapGetters(["isAuthenticated"]) },
    data() {
        return { loggedIn: false, a: null };
    },

    // methods: {
    //   async  isLoggedIn() {
    //     this.loggedIn =await this.isLoggedIn();
    // }
    // },
    async mounted() {
        initMenu();
        document.title = process.env.VUE_APP_TITLE;
        this.loggedIn = await isLoggedIn();
    },
};
</script>
