<template>
    <div class="flexy">
        <section class="hero">
            <div class="hero-body">
                <div class="level">
                    <div class="level-left">
                        <h1 class="title">Cheltuieli</h1>
                    </div>
                    <div class="level-right">
                        <button class="button is-primary" @click="add">
                            <span class="icon is-small">
                                <i class="fa fa-plus-circle"></i>
                            </span>
                            <span>Adauga</span>
                        </button>
                    </div>
                </div>
                <hr class="is-hidden-mobile" />
            </div>
        </section>
        <div class="has-text-centered">
            <b-field class="is-pulled-right is-clearfix">
                <b-input
                    placeholder="nr auto sau furnizor ..."
                    type="input"
                    icon="magnify"
                    v-model="query"
                    @keyup.native.enter="search"
                ></b-input>
                <p class="control">
                    <button
                        class="button is-primary is-outlined"
                        size="is-large"
                        @click="search"
                    >
                        Cauta
                    </button>
                </p>
            </b-field>

            <div class="is-clearfix"></div>

            <loading-info />

            <div class="table-container" v-show="!isLoading">
                <b-table
                    :data="expensesList"
                    :bordered="true"
                    :striped="true"
                    :narrowed="false"
                    :hoverable="true"
                >
                    <template  bordered>
                        <b-table-column
                            field="vehicleName"
                            width="130"
                            label="Nr. auto"
                            v-slot="props"
                            >{{ props.row.vehicleName }}</b-table-column
                        >

                        <b-table-column field="date" width="130" label="Data" v-slot="props">{{
                            props.row.date
                        }}</b-table-column>
                        <b-table-column
                            field="amount"
                            width="130"
                            label="Suma"
                             v-slot="props"
                            >{{ props.row.amount }}</b-table-column
                        >

                        <b-table-column
                            field="expenseType"
                            width="130"
                            label="Tip"
                             v-slot="props"
                            >{{ props.row.expenseType }}</b-table-column
                        >

                        <b-table-column
                            field="provider"
                            width=""
                            label="Furnizor"
                             v-slot="props"
                            >{{ props.row.provider }}</b-table-column
                        >

                        <!-- <b-table-column field="nr" width="130" label="Nr">{{ props.row.number }}</b-table-column> -->
                        <b-table-column field="details" label="Observatii"  v-slot="props">{{
                            props.row.details
                        }}</b-table-column>
                        <b-table-column label :centered="true" width="160"  v-slot="props">
                            <button
                                class="button is-small is-rounded"
                                @click="edit(props.row)"
                            >
                                <span class="icon is-small">
                                    <i class="fa fa-edit"></i>
                                </span>
                            </button>
                            <button
                                class="button is-small is-rounded"
                                @click="del(props.row)"
                            >
                                <span class="icon is-small has-text-danger">
                                    <i class="fa fa-trash"></i>
                                </span>
                            </button>
                        </b-table-column>
                    </template>
                    <template #empty>
                        <div class="has-text-centered">
                            <h1 class="is-size-5">
                                Nu am gasit nici o inregistrare.
                            </h1>
                            <div>
                                <button class="button is-text" @click="add">
                                    Adauga o inregistrare noua
                                </button>
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>

            <b-pagination
                v-show="!isLoading && expensesList.length > 0"
                :total="expenses.total"
                :current.sync="page"
                :simple="false"
                :rounded="false"
                :per-page="expenses.perPage"
                @change="doPaginate"
            ></b-pagination>
            <br />
            <br />
        </div>
    </div>
</template>

<script>
import ExpenseForm from "@/components/ExpenseForm";
import LoadingInfo from "@/components/LoadingInfo";

import { mapActions, mapState } from "vuex";

export default {
    name: "Expenses",
    components: { LoadingInfo },
    computed: {
        ...mapState(["expenses", "isLoading"]),
        expensesList() {
            return this.expenses.items || [];
        },
    },
    data() {
        return { query: "", page: 1 };
    },
    methods: {
        ...mapActions(["loadExpenses", "delExpense"]),
        add() {
            this.$buefy.modal.open({
                parent: this,
                component: ExpenseForm,
                hasModalCard: true,
                canCancel: false,
                props: { reload: this.doPaginate },
            });
        },
        edit(exp) {
            this.$buefy.modal.open({
                parent: this,
                component: ExpenseForm,
                hasModalCard: true,
                canCancel: false,
                props: { expenseId: exp.id, reload: this.doPaginate },
            });
        },
        del(e) {
            this.$buefy.dialog.confirm({
                message: `Sigur stergem : <b> ${e.expenseType}</b> din <b> ${
                    e.date
                } </b> in valoare de ${e.amount}?`,
                onConfirm: () => {
                    this.delExpense(e.id)
                        .then(() => {
                            this.doPaginate();
                            this.$toast.open("Cheltuiala stearsa!");
                        })
                        .catch((err) => console.log(err));
                },
            });
        },
        search() {
            this.page = 1;
            this.doPaginate();
        },
        doPaginate(page) {
            page = page || 1;
            this.page = page;
            this.loadExpenses({ page: page, query: this.query });
        },
    },
    mounted() {
        this.loadExpenses();
    },
};
</script>
