<template>
    <div class="flexy">
        <section class="hero">
            <div class="hero-body">
                <div class="level">
                    <div class="level-left">
                        <h1 class="title">Setari</h1>
                    </div>
                    <div  class="level-right is-hidden">
                        <button class="button is-primary" @click="add">
                            <span class="icon is-small">
                                <i class="fa fa-plus-circle"></i>
                            </span>
                            <span>Adauga</span>
                        </button>
                    </div>
                </div>
                <hr class="is-hidden-mobile" />
            </div>
        </section>
        <div class="has-text-centered">
            <b-field class="is-pulled-right is-clearfix  is-hidden">
                <b-input
                    placeholder="nume sau prenume ..."
                    type="input"
                    icon="magnify"
                    v-model="query"
                    @keyup.native.enter="search"
                ></b-input>
                <p class="control">
                    <button
                        class="button is-primary is-outlined"
                        size="is-large"
                        @click="search"
                    >
                        Cauta
                    </button>
                </p>
            </b-field>

            <div class="is-clearfix"></div>

            <loading-info />

            <!--Price plans-->
            
            <!--Price plans-->
            
            
            <!--<b-notification-->
            <!--    type="is-info"-->
            <!--    has-icon-->
            <!--    :closable="false"-->
            <!--    aria-close-label="Close notification">-->
            <!--    <h1 class="is-size-5 has-text-centered ">In curand ...</h1>-->
            <!--    </b-notification>-->

            <div class="table-container is-hidden" v-show="!isLoading">
                <b-table
                    :data="services.items"
                    :row-class="
                        (row, index) =>
                            row.isExpired &&
                            'has-background-danger has-text-white	'
                    "
                    :bordered="true"
                    :striped="true"
                    :narrowed="false"
                    :hoverable="true"
                >
                    <template slot-scope="props" bordered>
                        <b-table-column
                            field="name"
                            width="130"
                            label="Service"
                            >{{ props.row.name }}</b-table-column
                        >
                        <b-table-column
                            field="vehicleName"
                            width="130"
                            label="Nr. auto"
                            >{{ props.row.vehicleName }}</b-table-column
                        >
                        <b-table-column field="date" width="130" label="Data">{{
                            props.row.date
                        }}</b-table-column>
                        <b-table-column field="date" width="130" label="Km">{{
                            props.row.kms | nbr
                        }}</b-table-column>

                        <!-- <b-table-column field="comments" label="Observatii">{{ props.row.comments }}</b-table-column> -->
                        <b-table-column label :centered="true" width="160">
                            <button
                                class="button is-small is-rounded"
                                @click="edit(props.row)"
                            >
                                <span class="icon is-small">
                                    <i class="fa fa-edit"></i>
                                </span>
                            </button>
                            <button
                                class="button is-small is-rounded"
                                @click="del(props.row)"
                            >
                                <span class="icon is-small has-text-danger">
                                    <i class="fa fa-trash"></i>
                                </span>
                            </button>
                        </b-table-column>
                    </template>
                    <template #empty>
                        <div class="has-text-centered">
                            <h1 class="is-size-5">
                                Nu am gasit nici o inregistrare.
                            </h1>
                            <div>
                                <button class="button is-text" @click="add">
                                    Adauga o inregistrare noua
                                </button>
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>

            <b-pagination
                v-show="!isLoading && servicesList.length > 0"
                :total="services.total"
                :current.sync="page"
                :simple="false"
                :rounded="false"
                :per-page="services.perPage"
                @change="doPaginate"
            >
            </b-pagination>
        </div>
    </div>
</template>

<script>
import Form from "@/components/ServiceForm";
import LoadingInfo from "@/components/LoadingInfo";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
    name: "drivers",
    components: { LoadingInfo },
    data() {
        return {
            page: 1,
            query: "",
        };
    },
    computed: {
        ...mapGetters(["isLoading"]),
        ...mapState(["services"]),
        servicesList() {
            return this.services.items || [];
        },
    },

    methods: {
        ...mapActions(["loadServices", "delService"]),
        search() {
            this.page = 1;
            this.doPaginate();
        },
        add: function () {
            this.$modal.open({
                parent: this,
                component: Form,
                hasModalCard: true,
                canCancel: false,
                props: { reload: this.doPaginate },
            });
        },
        del(f) {
            this.$buefy.dialog.confirm({
                message: `Sigur stergem inregistrarea: <b> ${f.name}</b> ?`,
                onConfirm: () => {
                    this.delService(f.id)
                        .then(() => {
                            this.doPaginate();
                            this.$toast.open("Service sters!");
                        })
                        .catch((err) => console.log(err));
                },
            });
        },
        edit: function (f) {
            this.$modal.open({
                parent: this,
                component: Form,
                hasModalCard: true,
                canCancel: false,
                props: { serviceId: f.id, reload: this.doPaginate },
            });
        },
        doPaginate(page) {
            page = page || 1;
            this.loadServices({ page: page, query: this.query });
        },
    },
    created() {
       // this.doPaginate();
        // this.loadInsurances();
    },
};
</script>
