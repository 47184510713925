<template>
  <div>
    <div class="modal-card" v-show="!isLoading">
      <header class="modal-card-head">
        <p class="modal-card-title">Detalii autovehicul</p>
      </header>
      <section class="modal-card-body">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Nr. auto</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control is-expanded">
                <input
                  class="input"
                  type="text"
                  placeholder="B 101 ABC"
                  v-model="vehicle.name"
                  autofocus
                >
              </p>
              <p
                class="help is-danger"
                v-if="$v.vehicle.name.$dirty && !$v.vehicle.name.required"
              >Camp obligatoriu.</p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Carburant</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="vehicle.fuelTypeId">
                    <!-- <option   value="">Alege carburant</option> -->
                    <option
                      v-for="ft in fuelTypes"
                      :selected="ft.id == vehicle.fuelTypeId"
                      :key="ft.id"
                      :value="ft.id"
                    >{{ft.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Model</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Nissan Qashqai"
                  v-model="vehicle.model"
                >
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">ITP</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <b-datepicker
                  placeholder="Data expirare ITP"
                  v-model="vehicle.itp"
                  :date-formatter="dateFormatter"
                  editable
                  icon="calendar-today"
                ></b-datepicker>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">RCA</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <b-datepicker
                  placeholder="Data expirare RCA"
                  v-model="vehicle.rca"
                  :date-formatter="dateFormatter"
                  icon="calendar-today"
                ></b-datepicker>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Observatii</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <textarea
                  class="textarea"
                  placeholder="informatii suplimentare"
                  v-model="vehicle.comments"
                ></textarea>
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Renunta</button>
        <button class="button is-primary" :class="{'is-loading':isSaving}" @click.prevent="submit">
          <span class="icon is-small">
            <i class="fa fa-save"></i>
          </span>
          <span>Salveaza</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import LoadingInfo from "@/components/LoadingInfo";

import { EventBus } from "@/event-bus.js";

export default {
  name: "VehicleForm",
  props:['vehicleId','reload'],
  components: { LoadingInfo },
  computed: {
    ...mapGetters(["fuelTypes", "isLoading"])
  },
  data: function() {
    return {
      isSaving: false,
      vehicle: {
        id: null,
        fuelTypeId: 1,
        name: "",
        model: "",
        rca: null,
        itp: null,
        comments: ""
      }
    };
  },
  validations: {
    vehicle: {
      name: { required }
    }
  },
  methods: {
    ...mapActions([
      "saveVehicle",
      "loadVehicles",
      "loadVehicle",
      "loadDashboard",
      "loadInitialWithPromise"
    ]),
    dateFormatter(dt) {
      return dt.toLocaleDateString("ro-RO");
      //return dt.toLocaleDateString('ro-RO');
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSaving = !this.isSaving;
        this.saveVehicle(this.vehicle)
          .then(resp => {
            if (this.reload)
              this.reload();
            this.$parent.close();
            this.isSaving = !this.isSaving;
            EventBus.$emit("reload-data");
          })
          .catch(err => {
            this.isSaving = !this.isSaving;
            console.error(err);
          });
      }
    },
    load(id) {
      this.loadVehicle(id)
        .then(resp => {
          if (resp.data.rca) resp.data.rca = new Date(resp.data.rca);
          if (resp.data.itp) resp.data.itp = new Date(resp.data.itp);
          this.vehicle = resp.data;
        })
        .catch(err => console.log("error loading veh:", err));
    }
  },
  mounted() {
     this.loadInitialWithPromise().then(() => {
          var vid = this.vehicleId;
          if (vid > 0) {
            this.load(vid);
          }
     });
  }
};
</script>

