<template>
    <router-view></router-view>
</template>

<script>
import Vue from "vue";
import Buefy from "buefy";
Vue.use(Buefy, {
    defaultFirstDayOfWeek: 1,
    defaultMonthNames: [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie",
    ],
    defaultDayNames: ["Du", "Lu", "Ma", "Mi", "Jo", "Vi", "Sa"],
    //   defaultDateFormatter: function(date) {
    //     console.log("parsing date: " + date);
    //     return date;
    //   },
    //   defaultDateParser: date => {return 'date';}
});

import { mapGetters, mapActions } from "vuex";
import { isLoggedIn, login, logout } from "./utils/auth";
import { initMenu } from "./utils/burger";
import CookieLaw from "vue-cookie-law";

export default {
    components: { CookieLaw },
    computed: { ...mapGetters(["isAuthenticated"]) },
    methods: {
        ...mapActions(["loadVehiclesInitial","loadInitial"]),        
        isLoggedIn: async () => await isLoggedIn(),
    },
    async mounted() {        
        document.title = process.env.VUE_APP_TITLE;
        if (this.isAuthenticated){
          await this.loadVehiclesInitial();
          await this.loadInitial();
        }
        // TODO: load if not expired
        // load jwt if present
    },
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import "_variables";
// Import Bulma and Buefy styles
/* $primary: #45b2d3; */
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "_overides";

.pagination-link.is-current {
    background-color: #45b2d3 !important;
    border-color: #45b2d3 !important;
}

.pagination{ margin-bottom:30px;}
</style>
