<template>
  <div class="flexy">
    <section class="hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item"> 
              <h1 class="title" >Dashboard</h1>
            </div>
          </div>
          <div class="level-item is-hidden-mobile">
             <button class="button is-small1 is-primary is-outlined1" @click="addVehicle">
              <span class="icon is-small">
                <i class="fa fa-plus-circle"></i>
              </span>
              <span>autovehicul</span>
            </button> &nbsp;&nbsp;&nbsp;&nbsp;
            <button class="button is-small1 is-primary is-outlined1" @click="addFuel">
              <span class="icon is-small">
                <i class="fa fa-plus-circle"></i>
              </span>
              <span>carburant</span>
            </button>
          </div>
       
          <div class="level-left">
            <div class="level-item"><b-switch v-model="showGraphsCheck" class="is-smal1l is-outlined1">grafice</b-switch></div>
          </div>
        </div>
       <hr>
       <overview class="is-hidden-mobile"></overview>
        <div class="has-text-centered " hidden>
           
          <h2 class="subtitle">
            <button class="button is-small is-primary is-outlined1" @click="addVehicle">
              <span class="icon is-small">
                <i class="fa fa-plus-circle"></i>
              </span>
              <span>autovehicul</span>
            </button> &nbsp;
            <button class="button is-small is-primary is-outlined1" @click="addFuel">
              <span class="icon is-small">
                <i class="fa fa-plus-circle"></i>
              </span>
              <span>carburant</span>
            </button> &nbsp;
            <b-switch v-model="showGraphsCheck" class="is-small is-outlined1">grafice</b-switch>
            <hr>
          </h2>
          <div>
            <overview></overview>
          </div>
        </div>
      </div>
    </section> 

    <div class="has-text-centered">
      <dash-item v-for="v in dashboard" :vehicle="v" :key="v.id" :graphs="showGraphsCheck"/>
      <div
        class="is-size-3 mt_50"
        v-show="(dashboard == null || dashboard.length == 0 ) && !isLoading"
      >Nu am gasit nici o inregistrare!</div>
      <br>
      <div
        class="is-size-4"
        v-show="dashboard && dashboard.length == 0 && !isLoading"
      >Adauga autovehicule si bonuri de combustibil pentru a accesa graficele.</div>
      <br>
      <p class="subtitle" v-show="dashboard && dashboard.length == 0 && !isLoading">
        <button class="button is-text" @click="addVehicle">Adauga autovehicul</button> &nbsp;
        <button class="button is-text" @click="addFuel">Adauga carburant</button>
      </p>
      <loading-info/>
    </div>
  </div>
</template>

<script>
import VehicleForm from "@/components/VehicleForm";
import FuelForm from "@/components/FuelForm";
import DashItem from "@/components/DashItem";
import LoadingInfo from "@/components/LoadingInfo";
import Overview from "@/components/Overview";

import { mapGetters, mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "dashboard",
  components: { DashItem, LoadingInfo, Overview },
  data() {
    return {
      //showGraphs: true
    };
  },
  computed: {
    ...mapGetters([
     // "nrVehicles",
      "dashboard",
     // "noVehicles",
      "isLoading",
      "nrInsurances"
    ]),
    ...mapState(["showGraphs","totalVehicles"]),
    showGraphsCheck: {
      get() {
        return this.showGraphs;
      },
      set(value) {
        this.setShowGraphs(value);
      }
    },
    fuelsTitle() {
      return this.nrFuels && this.nrFuels.length == 1
        ? "1 bon carburant"
        : `${this.nrFuels.length} bonuri` + ` carburant`;
    },
    vehiclesTitle() {
      return this.totalVehicles && this.totalVehicles.length == 1
        ? "1 autovehicul"
        : `${this.totalVehicles.length} autovehicule`;
    },

    time() {
      return Date.now();
    }
  },
  methods: {
    ...mapActions(["loadDashboard","loadVehiclesInitial"]),
    ...mapMutations({
      setShowGraphs: "SET_SHOWGRAPHS"
    }),
    loadData() {
      this.loadVehiclesInitial();
      this.loadDashboard();
    },
    addVehicle: function() {
      this.$buefy.modal.open({
        parent: this,
        component: VehicleForm,
        hasModalCard: true,
        canCancel: false,
        props: { reload: this.loadData }
      });
    },
    addFuel: function() {
      this.$buefy.modal.open({
        parent: this,
        component: FuelForm,
        hasModalCard: true,
        canCancel: false,
        props: { reload: this.loadData }
      });
    }
  },
  created() {
    this.loadData();
  }
};
</script>

<style scoped>
.hero-body {
  padding-bottom: 0px;
}
</style>

