<template>
  <div class="flexy">
    <section class="hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <h1 class="title">Carburant</h1>
          </div>
          <div class="level-right">
            <button class="button is-primary" @click="addFuel">
              <span class="icon is-small">
                <i class="fa fa-plus-circle"></i>
              </span>
              <span>Adauga</span>
            </button>
          </div>
        </div>
        <hr class="is-hidden-mobile">
      </div>
    </section>

    <div class="has-text-centered">
      <b-field class="is-pulled-right is-clearfix" >                
        <b-input
          placeholder=" nr auto ..."
          clearable="true"
          type="input"
          icon="magnify"
          v-model="query"
          @keyup.native.enter="search"
          >
          </b-input>
        <p class="control">
          <button class="button is-primary is-outlined" @click="search">Cauta</button>
        </p>
      </b-field>

      <div class="is-clearfix"></div>

      <loading-info/>

      <div class="table-container" v-show="!isLoading">
        <b-table :data="fuels.items" :bordered="true" :striped="true" :narrowed="false" :hoverable="true">
          <template  bordered>
            <!-- <b-table-column field="id" label="ID" width="40" numeric>
                    {{ props.row.id }}
            </b-table-column>-->
            <b-table-column
              field="vehicleName"
              width="130"
              label="Nr. auto"
              v-slot="props"
            >{{ props.row.vehicleName }}</b-table-column>

            <b-table-column field="date" label="Data" v-slot="props">{{ props.row.date }}</b-table-column>

            <b-table-column field="kms" label="Km" v-slot="props">{{ props.row.kms | nbr}}</b-table-column>

            <b-table-column field="litres" label="Litri" v-slot="props">{{ props.row.litres | nbr }}</b-table-column>

            <b-table-column field="litrePrice" label="Pret/Litru" v-slot="props">{{ props.row.litrePrice | nbr('0.00')}} lei</b-table-column>

            <b-table-column field="fuelConsumption" label="%" v-slot="props">{{ props.row.fuelConsumption | nbr('0.00') }} %</b-table-column>

            <b-table-column field="price" label="Total" v-slot="props">{{ props.row.price | nbr}}</b-table-column>

            <b-table-column label :centered="true" width="140"  v-slot="props">
              <button class="button is-small is-rounded" @click="edit(props.row)">
                <span class="icon is-small">
                  <i class="fa fa-edit"></i>
                </span>
              </button>
              <button class="button is-small is-rounded" @click="del(props.row)">
                <span class="icon is-small has-text-danger">
                  <i class="fa fa-trash"></i>
                </span>
              </button>
            </b-table-column>
          </template>
          <template #empty>
            <div class="has-text-centered">
              <h1 class="is-size-5">Nu am gasit nici o inregistrare.</h1>
              <div>
                <button class="button is-text" @click="addFuel">Adauga un bon de carburant</button>
              </div>
            </div>
        </template>
        </b-table>
      </div>
<!-- 
      <div>
        <div v-show="fuelsList.length == 0" class="has-text-centered mt_50">
          <h1 class="is-size-5">Nu am gasit nici o inregistrare.</h1>
          <div>
            <button class="button is-text" @click="addFuel">Adauga un bon de carburant</button>
          </div>
        </div>
      </div> -->

        <b-pagination v-show="!isLoading && fuelsList.length > 0"
            :total="fuels.total"
            :current.sync="page"
            :simple="false"
            :rounded="false"
            :per-page="fuels.perPage"
            @change="doPaginate"
            >
        </b-pagination>

    </div>
  </div>
</template>

<script>
import FuelForm from "@/components/FuelForm";
import LoadingInfo from "@/components/LoadingInfo";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "dashboard",
  components: { LoadingInfo },
  data() {
    return { page: 1, query: "" };
  },
  computed: {
    ...mapGetters(["fuels", "nrFuels", "isLoading"]),
    fuelsList(){ return  this.fuels.items || []  }
  },

  methods: {
    ...mapActions(["loadFuels", "delFuel", "loadVehicles"]),
    search() {
      this.page =1;
      this.doPaginate();
    },
    clearSearch(){
      this.query ="";
      this.search();
    },
    addFuel: function() {
     
      this.$buefy.modal.open({
        parent: this,
        component: FuelForm,
        hasModalCard: true,
        canCancel: false,
        props: { reload: this.doPaginate}
      });
    },
    del(f) {
      this.$buefy.dialog.confirm({
        message: `Sigur stergem bonul de carburant: ${f.id} din ${
          f.date
        } in valoare de ${f.price} lei ?`,
        onConfirm: () => {
          this.delFuel(f.id)
            .then(() => {
              this.loadFuels();
              this.$toast.open("Bon carburant sters!");
            })
            .catch(err => console.log(err));
        }
      });
    },
    edit: function(f) {
      this.$buefy.modal.open({
        parent: this,
        component: FuelForm,
        hasModalCard: true,
        canCancel: false,
        props:{fuelId:f.id, reload: this.doPaginate}
      });
    },
    doPaginate(page){
      page = page || 1;
      this.page = page;
      this.loadFuels({ page: page, query: this.query });
    }
  },
  created() {
    // this.loadVehicles();
    this.loadFuels();
  }
};
</script>
