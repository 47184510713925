<template>
  <div class="section">
    <div class="card box">
        <header class="card-header has-text-centered ">          
          <div class="card-header-title is-centered is-size-4" aria-label="more options">
            <!-- <p> -->
             {{vehicle.name}}
            | {{vehicle.totalSpent | nbr}} lei
            | {{vehicle.totalKms |  nbr}} km
            <!-- </p> -->
          </div>
          
        </header>
      <footer class="card-footer" v-show="vehicle.totalSpent">
          <div class="card-footer-item" >
            <span class="" class=" is-medium" >  Combustibil {{(vehicle.totalSpent - vehicle.totalExpenses) | nbr}} lei</span>
          </div>
          <div class="card-footer-item">
              <span class="" class="is-medium" >  Cheltuieli {{vehicle.totalExpenses | nbr}} lei</span>
          </div>
        </footer>
        <div class="card-content"  v-show="graphs == true">         
          <div class="content">
            
            <line-chart 
                  v-show="vehicle.hasData == true" 
                  :colors="['#D36645', '#666']"
                  :data="chartData"
                  :legend="true"
                  height="200px"
                  :curve="false"                  
                  ></line-chart>  
            <p class="is-size-4" v-show="vehicle.hasData == false">
              Informatii insuficiente, adauga bonuri!
            <button class="button fa fa-plus-circle" @click="addFuel"></button></p>       
          </div>
        </div>
        <footer class="card-footer">
          <div class="card-footer-item">
            <span class="" :class="{'tag is-medium is-danger': vehicle.itpExpired == true}" >  ITP {{vehicle.itp}}</span>
            <span class="icon has-text-warning" v-show="!vehicle.itp">
                <i class="mdi mdi-alert mdi-18px"></i>
              </span>
          </div>
          <div class="card-footer-item">
              <span class="" :class="{'tag is-medium is-danger': vehicle.rcaExpired == true}" >  RCA {{vehicle.rca}}</span>
              <span class="icon has-text-warning" v-show="!vehicle.rca">
                <i class="mdi mdi-alert mdi-18px"></i>
              </span>
          </div>          
        </footer>
    </div>
   </div>
</template>
<script>
import FuelForm from "@/components/FuelForm";
import {mapActions, mapMutations} from 'vuex';


export default {
    name:"DashItem",
    // components: { LineChart },
    props:['vehicle','graphs']  ,
    computed:{
      chartData(){
        return [
              {name: 'Consum la 100Km', data: this.vehicle.items},
              {name: 'Consum mediu la 100Km', data: this.calculateAverage() }
            ]}
    },
    methods:{
    ...mapMutations(['SET_VEHICLE_FOR_FUEL']),
    calculateAverage: function(){
      let obj = this.vehicle.items;
      let avg = (Object.values(obj).reduce((a, b) => a + b, 0) / Object.values(obj).length).toFixed(1);
      let result = Object.keys(obj).reduce((res, key) => ({...res, [key]: avg}), {});
      // console.log(result); // Output: { '2024-05-31': '5.6', '2024-06-01': '5.6', '2024-06-02': '5.6' }
      return result;
    },
      addFuel: function() {
      this.SET_VEHICLE_FOR_FUEL(this.vehicle.id);
      this.$buefy.modal.open({
        parent: this,
        component: FuelForm,
        hasModalCard: true,
        canCancel: false,
        props:{
          vehicleId : this.vehicle.id
        }
      });
    }
    },
}
</script>

<style scoped>
.section{
  padding-bottom: 0rem !important;
}
</style>

