<template>
  <div class="flexy">
    <section class="hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <h1 class="title">Accounts</h1>
          </div>
          <!-- <div class="level-right">
            <button class="button is-primary" @click="add">
              <span class="icon is-small">
                <i class="fa fa-plus-circle"></i>
              </span>
              <span>Adauga</span>
            </button>
          </div> -->
        </div>
        <hr class="is-hidden-mobile">
      </div>
    </section>
    <div class="has-text-centered">
      <b-field class="is-pulled-right is-clearfix" >
        <b-input
          placeholder="email ..."
          type="input"
          icon="magnify"
          v-model="query"
          @keyup.native.enter="search"
        ></b-input>
        <p class="control">
          <button class="button is-primary is-outlined" size="is-large" @click="search">Cauta</button>
        </p>
      </b-field>

      <div class="is-clearfix"></div>

      <loading-info/>

      <div class="table-container" v-show="!isLoading">
        <b-table
          :data="accounts"
          :row-class="(row, index) => row.isExpired  && 'has-background-danger has-text-white	'"
          :bordered="true"
          :striped="true"
          :narrowed="false"
          :hoverable="true"
        >
          <template  bordered>
            <b-table-column field="email" width="130" label="Email" cellClass="has-text-left" v-slot="props" >{{ props.row.email }}</b-table-column>

            <b-table-column field="vehicles"  label="Vehicule" v-slot="props">{{ props.row.vehiclesCount }}</b-table-column>
            <b-table-column field="vehicles"  label="Asigurari" v-slot="props">{{ props.row.insurancesCount }}</b-table-column>
            <b-table-column field="vehicles"  label="Carburanti" v-slot="props">{{ props.row.fuelsSum | nbr }} lei</b-table-column>
            <b-table-column field="vehicles"  label="Cheltuieli" v-slot="props">{{ props.row.expensesSum | nbr}} lei</b-table-column>
            <b-table-column field="vehicles"  label="Service" v-slot="props">{{ props.row.servicesCount }}</b-table-column>
<!-- 
            <b-table-column
              field="vehicleName"
              width="130"
              label="Nr. auto"
            >{{ props.row.vehicleName }}</b-table-column>

            <b-table-column field="comments" label="Observatii">{{ props.row.comments }}</b-table-column> -->
            <b-table-column label :centered="true" width="160">
              <button class="button is-small is-rounded" @click="become(props.row)">
                <span class="icon is-small">
                  <i class="fa fa-sync"></i>
                </span>
              </button>
             
            </b-table-column>
          </template>
          
        </b-table>
      </div>

     

       <b-pagination v-show="!isLoading && accounts.length > 0" />
            <!-- :total="accounts.total"
            :current.sync="page"
            :simple="false"
            :rounded="false"
            :per-page="insurances.perPage"
            @change="doPaginate"
            
        </b-pagination>  -->


    </div>
  </div>
</template>

<script>

import LoadingInfo from "@/components/LoadingInfo";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "accounts",
  components: { LoadingInfo },
  data() {
    return {
      page: 1,
      query: ""
    };
  },
  computed: {
    ...mapGetters(["accounts",  "isLoading"]),    
  },

  methods: {
    ...mapActions(["loadAccounts"]),
    search() {
      this.page = 1;
      this.doPaginate();
    },   
    become: function(f) {
      //TODO: create this
    },
    doPaginate(page){
      page = page || 1;
      this.loadAccounts({ page: page, query: this.query });
    }
  },
  mounted() {
    this.doPaginate();
  }
};
</script>

