<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card" v-show="!isLoading">
        <header class="modal-card-head">
          <p class="modal-card-title">Detalii asigurare</p>
        </header>
        <section class="modal-card-body">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Asigurare</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="insurance.name"
                    placeholder="denumire asigurare"
                    autofocus
                  >
                </p>
                <p
                  class="help is-danger"
                  v-if="$v.insurance.name.$dirty && !$v.insurance.name.required"
                >Specifica denumirea asigurarii.</p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Autovehicul</label>
            </div>
            <div class="field-body">
              <div class="field is-narrow">
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="insurance.vehicleId">
                      <option value>Alege autovehicul</option>
                      <option v-for="v in vehiclesList" :key="v.id" :value="v.id">{{v.name}}</option>
                    </select>
                  </div>
                  <p
                    class="help is-danger"
                    v-if="($v.insurance.vehicleId.$dirty && !$v.insurance.vehicleId.required) || ($v.insurance.vehicleId.$dirty && !$v.insurance.vehicleId.minLength)"
                  >Alege autovehiculul</p>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Data Expirare</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <b-datepicker
                    placeholder="Data bon"
                    v-model="insurance.expirationDate"
                    :date-formatter="dateFormatter"
                    icon="calendar-today"
                  ></b-datepicker>
                </p>
              </div>
            </div>
          </div>

          <!-- <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Obligatorie</label>
                </div>
                <div class="field-body">
                    <div class="field">
                    <p class="control">
                        <b-switch v-model="insurance.isRequired"></b-switch> 
                    </p>
                    </div>    
                </div>
          </div>-->

 <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Email alternativ</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <b-taginput
                      v-model="insurance.otherEmails" 
                      :before-adding="beforeAdding"
                      placeholder="adrese de email alternative pentru notificari">
                  </b-taginput>
                  <!-- <input
                    class="input"
                    type="text"
                    v-model="insurance.emails"
                    
                    autofocus
                  > -->
                </p>
                <p
                  class="help is-danger"
                  v-if="$v.insurance.name.$dirty && !$v.insurance.name.required"
                >Specifica denumirea asigurarii.</p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Observatii</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <textarea
                    class="textarea"
                    v-model="insurance.comments"
                    placeholder="informatii suplimentare"
                  ></textarea>
                </p>
              </div>
            </div>
          </div>
            <p class="help is-info has-text-centered">Iti vom trimite un email inainte cu 7 zile inainte de data de expirare a asigurarii.</p>
        </section>
        

        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$parent.close()">Renunta</button>
          <button class="button is-primary" v-bind:class="{'is-loading' : isSaving == true}">
            <span class="icon is-small">
              <i class="fa fa-save"></i>
            </span>
            <span>Salveaza</span>
          </button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { required, minLength } from "vuelidate/lib/validators";
import LoadingInfo from "@/components/LoadingInfo";

export default {
  name: "InsuranceForm",
  props: ["insuranceId", "reload"],
  components: { LoadingInfo },
  computed: {
    ...mapGetters(["vehiclesList", "isLoading"])
  },
  data: function() {
    return {
      isSaving: false,
      insurance: {
        vehicleId: "",
        expirationDate: new Date(),
        name: null,
        comments: "",
        otherEmails:[]
      }
    };
  },
  validations: {
    insurance: {
      vehicleId: { required, minLength: minLength(1) },
      expirationDate: { required },
      name: { required }
    }
  },
  methods: {
    ...mapActions([
      "saveInsurance",
      "loadInsurances",
      "loadInsurance",
      "loadDashboard",
      "loadInitialWithPromise"
    ]),
    dateFormatter(dt) {
      return dt.toLocaleDateString("ro-RO");
    },
    save() {
      //todo: validate
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSaving = !this.isSaving;
        this.saveInsurance(this.insurance)
          .then(resp => {
            if (this.reload)
              this.reload();

            this.$parent.close();
            this.isSaving = !this.isSaving;
          })
          .catch(err => {
            console.error("error: " + err);
            this.isSaving = !this.isSaving;
          });
      }
    },
    beforeAdding(email){
        var reg = /\S+@\S+\.\S+/;
        return reg.test(email);    
    },
    load(id) {
      this.loadInsurance(id)
        .then(resp => {
          this.insurance = resp.data;
          this.insurance.expirationDate = new Date(
            this.insurance.expirationDate
          );
          if (!this.vehiclesList && this.vehiclesList.length == 0) {
            this.insurance.vehicleId = null;
          }
        })
        .catch(err => console.log("error loading veh:", err));
    }
  },
  mounted() {
    this.loadInitialWithPromise().then(() => {
      var id = this.insuranceId;
      if (this.vehicleId) this.insurance.vehicleId = this.vehicleId;
      var dt = this.insurance.expirationDate;
      dt.setFullYear(dt.getFullYear() + 1);
      this.insurance.expirationDate = dt;
      if (id > 0) {
        this.load(id);
      }
    });
  }
};
</script>

