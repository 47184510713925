<template>
  <div class="flexy">
    <section class="hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <h1 class="title">Asigurari</h1>
          </div>
          <div class="level-right">
            <button class="button is-primary" @click="add">
              <span class="icon is-small">
                <i class="fa fa-plus-circle"></i>
              </span>
              <span>Adauga</span>
            </button>
          </div>
        </div>
        <hr class="is-hidden-mobile">
      </div>
    </section>
    <div class="has-text-centered">
      <b-field class="is-pulled-right is-clearfix" >
        <b-input
          placeholder="asigurare sau nr auto ..."
          type="input"
          icon="magnify"
          v-model="query"
          @keyup.native.enter="search"
        ></b-input>
        <p class="control">
          <button class="button is-primary is-outlined" size="is-large" @click="search">Cauta</button>
        </p>
      </b-field>

      <div class="is-clearfix"></div>

      <loading-info/>

      <div class="table-container" v-show="!isLoading">
        <b-table
          :data="insurances.items"
          :row-class="(row, index) => row.isExpired  && 'has-background-danger has-text-white	'"
          :bordered="true"
          :striped="true"
          :narrowed="false"
          :hoverable="true"
        >
          <template  bordered>
            <b-table-column field="date" width="130" label="Data" v-slot="props">{{ props.row.expirationDate }}</b-table-column>

            <b-table-column field="name" width="130" label="Asigurare" v-slot="props">{{ props.row.name }}</b-table-column>

            <b-table-column
              field="vehicleName"
              width="130"
              label="Nr. auto"
              v-slot="props"
            >{{ props.row.vehicleName }}</b-table-column>

            <b-table-column field="comments" label="Observatii" v-slot="props">{{ props.row.comments }}</b-table-column>
            <b-table-column label :centered="true" width="160" v-slot="props">
              <button class="button is-small is-rounded" @click="edit(props.row)">
                <span class="icon is-small">
                  <i class="fa fa-sync"></i>
                </span>
              </button>
              <button class="button is-small is-rounded" @click="del(props.row)">
                <span class="icon is-small has-text-danger">
                  <i class="fa fa-trash"></i>
                </span>
              </button>
            </b-table-column>
          </template>
          <template #empty>
            <div class="has-text-centered">
              <h1 class="is-size-5">Nu am gasit nici o inregistrare.</h1>
              <div>
                <button class="button is-text" @click="add">Adauga o asigurare noua</button>
              </div>
            </div>
        </template>
        </b-table>
      </div>

      <!-- <div>
        <div v-show="insurancesList.length == 0" class="has-text-centered mt_50">
          <h1 class="is-size-5">Nu am gasit nici o inregistrare.</h1>
          <div>
            <button class="button is-text" @click="add">Adauga o asigurare noua</button>
          </div>
        </div>
      </div> -->

      <b-pagination v-show="!isLoading && insurancesList.length > 0"
            :total="insurances.total"
            :current.sync="page"
            :simple="false"
            :rounded="false"
            :per-page="insurances.perPage"
            @change="doPaginate"
            >
        </b-pagination>


    </div>
  </div>
</template>

<script>
import InsuranceForm from "@/components/InsuranceForm";
import LoadingInfo from "@/components/LoadingInfo";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "insurances",
  components: { LoadingInfo },
  data() {
    return {
      page: 1,
      query: ""
    };
  },
  computed: {
    ...mapGetters(["insurances", "nrFuels", "isLoading"]),
    ...mapState(["insurances"]),
    insurancesList() {return  this.insurances.items || []}
  },

  methods: {
    ...mapActions(["loadInsurances", "delInsurance", "loadVehicles"]),
    search() {
     // throw "Front End error for logging purpose";
      
      this.page = 1;
      this.doPaginate();
    },
    add: function() {
      this.$buefy.modal.open({
        parent: this,
        component: InsuranceForm,
        hasModalCard: true,
        canCancel: false,
         props: { reload: this.doPaginate}
      });
    },
    del(f) {
      this.$buefy.dialog.confirm({
        message: `Sigur stergem asigurarea: <b> ${f.name}</b> din <b> ${
          f.expirationDate
        } </b>?`,
        onConfirm: () => {
          this.delInsurance(f.id)
            .then(() => {
              this.loadInsurances();
              this.$toast.open("Asigurare stersa!");
            })
            .catch(err => console.log(err));
        }
      });
    },
    edit: function(f) {
      this.$buefy.modal.open({
        parent: this,
        component: InsuranceForm,
        hasModalCard: true,
        canCancel: false,
        props: { insuranceId: f.id, reload: this.doPaginate }
      });
    },
    doPaginate(page){
      page = page || 1;
      this.loadInsurances({ page: page, query: this.query });
    }
  },
  created() {
    this.doPaginate();
    // this.loadVehicles();
    // this.loadInsurances();
  }
};
</script>

