<template>    
    <!-- <div v-show="isLoading" class="is-size-4"> -->
           <b-loading  :active.sync="isLoading" :is-full-page=true ></b-loading>
           <!-- aducem datele ...</div> -->
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name:'LoadingInfo',
    computed:{...mapGetters(['isLoading'])}
}
</script>

