import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
import { email } from "vuelidate/lib/validators";

Vue.use(Vuex);

const apiUrl = process.env.VUE_APP_API_URL;

export default new Vuex.Store({
  state: {
    jwt: sessionStorage.getItem("combu-jwt") || null,
    phone: "",
    fTypes: [],
    eTypes: [],
    vehicles: [],
    vehiclesList: [],
    fuels: [],
    insurances: [],
    expenses: [],
    services: [],
    dashboard: [],
    totalVehicles: 0,
    totalFuels: 0,
    totalInsurances: 0,
    totalExpenses: 0,
    isLoading: false,
    newFuelVehicleId: null,
    showGraphs: true,
    events: [],
    accounts: [],
    isAdmin: false,
  },
  getters: {
    getAuthHeader: (state) => {
      // console.log("getAuthHeader in store with jwt:", state.jwt)
      return {
        headers: {
          AuthorizationToken: `Bearer ${state.jwt}`,
          "Content-Type": "application/json",
        },
      };
    },
    jwt: (store) => store.jwt,
    fuelTypes: (store) => store.fTypes,
    vehiclesList: (store) => store.vehiclesList,
    vehicles: (store) => store.vehicles,
    fuels: (store) => store.fuels,
    currentPhone: (store) => store.phone,
    isAuthenticated: (store) => !!store.jwt,
    dashboard: (store) => store.dashboard,
    isLoading: (store) => store.isLoading > 0,
    newFuelVehicleId: (store) => store.newFuelVehicleId,
    nrInsurances: (store) => store.totalInsurances,
    isAdmin: (store) => store.isAdmin,
    accounts: (store) => store.accounts,
  },
  mutations: {
    SET_PHONE: (store, phone) => (store.phone = phone),
    SET_JWT: (store, jwt) => (store.jwt = jwt),
    SET_TYPES: (store, types) => {
      (store.fTypes = types.fuelTypes), (store.eTypes = types.expenseTypes);
    },
    SET_VEHICLES: (store, vehicles) => (store.vehicles = vehicles),
    SET_FUELS: (store, fuels) => (store.fuels = fuels),
    SET_DASHBOARD: (store, dash) => {
      store.totalVehicles = dash.vehicles;
      store.totalFuels = dash.fuels;
      store.dashboard = dash.data;
      store.totalInsurances = dash.insurances;
      store.totalExpenses = dash.expenses;
      store.insurancesExpired = dash.insurancesExpired;
      store.insurancesAboutToExpire = dash.insurancesAboutToExpire;
    },
    SET_IS_LOADING: (store, nr) => (store.isLoading += nr),
    SET_VEHICLE_FOR_FUEL: (store, id) => (store.newFuelVehicleId = id),
    SET_INSURANCES: (store, insurances) => (store.insurances = insurances),
    SET_EXPENSES: (store, expenses) => (store.expenses = expenses),
    SET_SHOWGRAPHS: (store, showIt) => (store.showGraphs = showIt),
    SET_VEHICLESLIST: (store, vehicles) => (store.vehiclesList = vehicles),
    SET_SERVICES: (store, services) => (store.services = services),
    SET_EVENTS: (store, events) => (store.events = events),
    SET_ACCOUNTS: (store, accounts) => (store.accounts = accounts),
    SET_MENU: (store, data) => (store.isAdmin = data.isAdmin),
  },
  actions: {
    setPhone({ commit, dispatch }, phone) {
      Vue.axios.defaults.headers.common["phone"] = phone;
      commit("SET_PHONE", phone);
      dispatch("loadFuels");
      dispatch("loadVehicles");
    },
    async logout({ commit, getters }) {
      commit("SET_PHONE", "");
      commit("SET_VEHICLES", []);
      commit("SET_VEHICLESLIST", []);
      commit("SET_INSURANCES", []);
      commit("SET_FUELS", []);
      commit("SET_EXPENSES", []);
      commit("SET_JWT", ""), sessionStorage.clear();
      commit("SET_MENU", { isAdmin: false });
      localStorage.clear();
      //TODO: logout
      try {
        console.log("Preparing to logout ....");
        let resp = await Vue.axios.get(`${apiUrl}/api/logout`);
        console.log("logged out?");
        console.log(resp);
      } catch (e) {
        console.log("Error loging out ");
        console.error(e.message);
        console.dir(e);
      } finally {
        console.log("Logging out complete!");
      }
    },
    //this one si used just in case on forms the dependencies are not present
    loadInitialWithPromise({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        if (state.fTypes && state.fTypes.length > 0) {
          return resolve();
        } else {
          //  fetch(`${apiUrl}/api/types`)
          //  .then(resp =>{
          //   commit("SET_TYPES", resp.json());
          //  return resolve();})
          //  .catch(err => {
          //   console.error(err);
          //   return reject();
          // })

          Vue.axios
            .get(`${apiUrl}/api/types`, getters.getAuthHeader)
            .then((resp) => {
              commit("SET_TYPES", resp.data);
              return resolve();
            })
            .catch((err) => {
              console.error(err);
              return reject();
            });
        }
      });
    },
    loadInitial({ commit, getters }) {
      Vue.axios
        .get(`${apiUrl}/api/types`, getters.getAuthHeader)
        .then((resp) => commit("SET_TYPES", resp.data))
        .catch((err) => console.error(err));
    },
    async loginByEmail({ commit }, email) {
      var resp = await Vue.axios.post(`${apiUrl}/api/login?email=${email}`, {});
      return;
    },
    async authenticateByEmailAndToken({ commit }, req) {
      var resp = await Vue.axios.post(`${apiUrl}/api/validate`, {
        email: req.email,
        token: req.token,
      });
      // console.log("validate response:", JSON.stringify(resp));
      if (resp.data) {
        commit("SET_JWT", resp.data);
        sessionStorage.setItem("combu-jwt", resp.data);
      }
      return resp.data;
    },

    loadVehiclesInitial({ commit, getters }) {
      commit("SET_IS_LOADING", 1);
      var url = `${apiUrl}/api/vehicles/list`;
      // if (query && query.length > 0)
      //   url += `query=${query}`;
      Vue.axios
        .get(url, getters.getAuthHeader)
        .then((resp) => {
          commit("SET_VEHICLESLIST", resp.data);
          commit("SET_IS_LOADING", -1);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_IS_LOADING", -1);
        });
    },
    loadVehicles({ commit, dispatch, getters }, req) {
      commit("SET_IS_LOADING", 1);
      req = req || { page: 1, query: "" };
      var url = `${apiUrl}/api/vehicles?page=${req.page}`;
      if (req.query && req.query.length > 0) url += `&query=${req.query}`;
      // load all vehicles
      if (req.loadAll) url += `&loadAll=true`;

      Vue.axios
        .get(url, getters.getAuthHeader)
        .then((resp) => {
          commit("SET_VEHICLES", resp.data);
          commit("SET_IS_LOADING", -1);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_IS_LOADING", -1);
        });
    },
    loadDashboard({ commit, dispatch, getters }) {
      commit("SET_IS_LOADING", 1);
      Vue.axios
        .get(`${apiUrl}/api/dashboard`, getters.getAuthHeader)
        .then((resp) => {
          var dta = resp.data.items.map((x) => {
            var obj = { items: {} };
            Object.assign(obj, x.vehicle);

            obj.hasData = (x.fuels || []).length > 1;
            obj.fuels = (x.fuels || []).map((f) => {
              var o = {};
              obj.items[f.date] = f.consumption;
              return o;
            });
            return obj;
          });
          commit("SET_DASHBOARD", {
            data: dta,
            vehicles: resp.data.vehicles,
            fuels: resp.data.fuels,
            insurances: resp.data.insurances,
            insurancesExpired: resp.data.insurancesExpired,
            insurancesAboutToExpire: resp.data.insurancesAboutToExpire,
            expenses: resp.data.expenses,
          });
          commit("SET_IS_LOADING", -1);
          dispatch("loadVehicles", { page: 1, query: "", loadAll: true });
        })
        .catch((err) => {
          console.error(err);
          commit("SET_IS_LOADING", -1);
        });
    },
    saveVehicle({ dispatch, getters }, vehicle) {
      vehicle = Object.assign({}, vehicle);
      if (vehicle.rca)
        vehicle.rca =
          vehicle.rca.getFullYear() +
          "-" +
          (vehicle.rca.getMonth() + 1) +
          "-" +
          vehicle.rca.getDate();
      if (vehicle.itp)
        vehicle.itp =
          vehicle.itp.getFullYear() +
          "-" +
          (vehicle.itp.getMonth() + 1) +
          "-" +
          vehicle.itp.getDate();
      vehicle.name = vehicle.name.toUpperCase();

      var promise =
        vehicle.id > 0
          ? Vue.axios.patch(
              `${apiUrl}/api/vehicles`,
              vehicle,
              getters.getAuthHeader
            )
          : Vue.axios.post(
              `${apiUrl}/api/vehicles`,
              vehicle,
              getters.getAuthHeader
            );

      return new Promise((resolve, reject) => {
        return promise
          .then((resp) => {
            dispatch("loadVehiclesInitial");
            return resolve(resp);
          })
          .catch((err) => reject(err));
      });
    },
    delVehicle({ commit, dispatch, getters }, id) {
      //--------------- WIP ----------
      return new Promise((resolve, reject) => {
        return Vue.axios
          .delete(`${apiUrl}/api/vehicles/${id}`, getters.getAuthHeader)
          .then((resp) => {
            dispatch("loadVehiclesInitial");
            return resolve(resp);
          })
          .catch((err) => reject(err));
      });
    },
    saveFuel: ({ commit, dispatch, getters }, fuel) => {
      fuel = Object.assign({}, fuel);
      fuel.date =
        fuel.date.getFullYear() +
        "-" +
        (fuel.date.getMonth() + 1) +
        "-" +
        fuel.date.getDate();

      var promise =
        fuel.id > 0
          ? Vue.axios.patch(`${apiUrl}/api/fuels`, fuel, getters.getAuthHeader)
          : Vue.axios.post(`${apiUrl}/api/fuels`, fuel, getters.getAuthHeader);
      return new Promise((resolve, reject) => {
        return promise
          .then((resp) => {
            dispatch("loadVehiclesInitial");
            return resolve(resp);
          })
          .catch((err) => reject(err));
      });
    },
    loadFuels({ commit, getters }, req) {
      commit("SET_IS_LOADING", 1);
      req = req || { page: 1 };
      var url = `${apiUrl}/api/fuels?page=${req.page}`;
      if (req.query && req.query.length > 0) url += `&query=${req.query}`;

      Vue.axios
        .get(url, getters.getAuthHeader)
        .then((resp) => {
          commit("SET_FUELS", resp.data);
          commit("SET_IS_LOADING", -1);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_IS_LOADING", -1);
        });
    },
    delFuel({ getters }, id) {
      return Vue.axios.delete(
        `${apiUrl}/api/fuels/${id}`,
        getters.getAuthHeader
      );
    },
    loadVehicle({ commit, getters }, id) {
      commit("SET_IS_LOADING", 1);
      return new Promise(function (resolve, reject) {
        return Vue.axios
          .get(`${apiUrl}/api/vehicles/${id}`, getters.getAuthHeader)
          .then((resp) => {
            commit("SET_IS_LOADING", -1);
            return resolve(resp);
          })
          .catch((err) => {
            commit("SET_IS_LOADING", -1);
            return reject(err);
          });
      });
    },
    loadVehicle({ commit, getters }, id) {
      commit("SET_IS_LOADING", 1);
      return new Promise(function (resolve, reject) {
        return Vue.axios
          .get(`${apiUrl}/api/vehicles/${id}`, getters.getAuthHeader)
          .then((resp) => {
            commit("SET_IS_LOADING", -1);
            return resolve(resp);
          })
          .catch((err) => {
            commit("SET_IS_LOADING", -1);
            return reject(err);
          });
      });
    },
    loadFuel({ commit, getters }, id) {
      commit("SET_IS_LOADING", 1);
      var promise = new Promise(function (resolve, reject) {
        return Vue.axios
          .get(`${apiUrl}/api/fuels/${id}`, getters.getAuthHeader)
          .then((resp) => {
            commit("SET_IS_LOADING", -1);
            return resolve(resp);
          })
          .catch((err) => {
            commit("SET_IS_LOADING", -1);
            return reject(err);
          });
      });
      return promise;
    },
    loadInsurances({ commit, getters }, req) {
      commit("SET_IS_LOADING", 1);
      req = req || { page: 1 };
      var url = `${apiUrl}/api/insurances?page=${req.page}`;
      if (req.query && req.query.length > 0) url += `&query=${req.query}`;
      Vue.axios
        .get(url, getters.getAuthHeader)
        .then((resp) => {
          commit("SET_INSURANCES", resp.data);
          commit("SET_IS_LOADING", -1);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_IS_LOADING", -1);
        });
    },
    saveInsurance: ({ getters }, insurance) => {
      insurance = Object.assign({}, insurance);
      insurance.expirationDate =
        insurance.expirationDate.getFullYear() +
        "-" +
        (insurance.expirationDate.getMonth() + 1) +
        "-" +
        insurance.expirationDate.getDate();

      return insurance.id > 0
        ? Vue.axios.patch(
            `${apiUrl}/api/insurances`,
            insurance,
            getters.getAuthHeader
          )
        : Vue.axios.post(
            `${apiUrl}/api/insurances`,
            insurance,
            getters.getAuthHeader
          );
    },
    delInsurance({ getters }, id) {
      return Vue.axios.delete(
        `${apiUrl}/api/insurances/${id}`,
        getters.getAuthHeader
      );
    },
    loadInsurance({ commit, getters }, id) {
      commit("SET_IS_LOADING", 1);
      var promise = new Promise(function (resolve, reject) {
        return Vue.axios
          .get(`${apiUrl}/api/insurances/${id}`, getters.getAuthHeader)
          .then((resp) => {
            commit("SET_IS_LOADING", -1);
            return resolve(resp);
          })
          .catch((err) => {
            commit("SET_IS_LOADING", -1);
            return reject(err);
          });
      });
      return promise;
    },
    sendContact: ({ getters }, message) => {
      return Vue.axios.post(
        `${apiUrl}/api/contact`,
        message,
        getters.getAuthHeader
      );
    },
    saveExpense: ({ getters }, expense) => {
      expense = Object.assign({}, expense);
      expense.date =
        expense.date.getFullYear() +
        "-" +
        (expense.date.getMonth() + 1) +
        "-" +
        expense.date.getDate();

      return expense.id > 0
        ? Vue.axios.patch(
            `${apiUrl}/api/expenses`,
            expense,
            getters.getAuthHeader
          )
        : Vue.axios.post(
            `${apiUrl}/api/expenses`,
            expense,
            getters.getAuthHeader
          );
    },
    loadExpenses({ commit, getters }, req) {
      //TODO: load required if missing
      commit("SET_IS_LOADING", 1);
      req = req || { page: 1 };
      var url = `${apiUrl}/api/expenses?page=${req.page}`;
      if (req.query && req.query.length > 0) url += `&query=${req.query}`;

      Vue.axios
        .get(url, getters.getAuthHeader)
        .then((resp) => {
          commit("SET_EXPENSES", resp.data);
          commit("SET_IS_LOADING", -1);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_IS_LOADING", -1);
        });
    },
    loadExpense({ commit, getters }, id) {
      commit("SET_IS_LOADING", 1);
      var promise = new Promise(function (resolve, reject) {
        return Vue.axios
          .get(`${apiUrl}/api/expenses/${id}`, getters.getAuthHeader)
          .then((resp) => {
            commit("SET_IS_LOADING", -1);
            return resolve(resp);
          })
          .catch((err) => {
            commit("SET_IS_LOADING", -1);
            return reject(err);
          });
      });
      return promise;
    },
    delExpense({ getters }, id) {
      return Vue.axios.delete(
        `${apiUrl}/api/expenses/${id}`,
        getters.getAuthHeader
      );
    },
    saveService({ commit, getters }, obj) {
      obj = Object.assign({}, obj);
      if (obj.date)
        obj.date =
          obj.date.getFullYear() +
          "-" +
          (obj.date.getMonth() + 1) +
          "-" +
          obj.date.getDate();

      //TODO: direct return no promise required
      var promise =
        obj.id > 0
          ? Vue.axios.patch(
              `${apiUrl}/api/services`,
              obj,
              getters.getAuthHeader
            )
          : Vue.axios.post(
              `${apiUrl}/api/services`,
              obj,
              getters.getAuthHeader
            );

      return new Promise((resolve, reject) => {
        return promise
          .then((resp) => {
            return resolve(resp);
          })
          .catch((err) => reject(err));
      });
    },
    loadServices({ commit, getters }, req) {
      commit("SET_IS_LOADING", 1);
      req = req || { page: 1, query: "" };
      var url = `${apiUrl}/api/services?page=${req.page}`;
      if (req.query && req.query.length > 0) url += `&query=${req.query}`;

      Vue.axios
        .get(url, getters.getAuthHeader)
        .then((resp) => {
          commit("SET_SERVICES", resp.data);
          commit("SET_IS_LOADING", -1);
        })
        .catch((err) => {
          console.error(err);
          commit("SET_IS_LOADING", -1);
        });
    },
    loadService({ commit, getters }, id) {
      commit("SET_IS_LOADING", 1);
      return new Promise(function (resolve, reject) {
        return Vue.axios
          .get(`${apiUrl}/api/services/${id}`, getters.getAuthHeader)
          .then((resp) => {
            commit("SET_IS_LOADING", -1);
            return resolve(resp);
          })
          .catch((err) => {
            commit("SET_IS_LOADING", -1);
            return reject(err);
          });
      });
    },
    delService({ commit, dispatch, getters }, id) {
      return Vue.axios.delete(
        `${apiUrl}/api/services/${id}`,
        getters.getAuthHeader
      );
    },

    async loadEvents({ commit, dispatch, getters }, data) {
      commit("SET_IS_LOADING", 1);
      try {
        const resp = await Vue.axios.get(
          `${apiUrl}/api/events?start=${data.start.split("T")[0]}&end=${
            data.end.split("T")[0]
          }`,
          getters.getAuthHeader
        );

        commit(
          "SET_EVENTS",
          resp.data.map((x, i) => {
            x.id = i;
            x.backgroundColor =
              new Date(x.start) < new Date() ? "#F06E5A" : "#45b2d3";
            return x;
          })
        );
      } finally {
        commit("SET_IS_LOADING", -1);
      }
    },

    async loadMenu({ commit, getters }) {
      commit("SET_IS_LOADING", 1);
      try {
        const resp = await Vue.axios.get(
          `${apiUrl}/api/menu`,
          getters.getAuthHeader
        );

        commit("SET_MENU", resp.data);
      } finally {
        commit("SET_IS_LOADING", -1);
      }
    },
    async loadAccounts({ commit, getters }, req) {
      try {
        commit("SET_IS_LOADING", 1);
        req = req || { page: 1 };
        var url = `${apiUrl}/api/accounts?page=${req.page}`;
        if (req.query && req.query.length > 0) url += `&query=${req.query}`;
        const resp = await Vue.axios.get(url, getters.getAuthHeader);
        commit("SET_ACCOUNTS", resp.data.items || []);
      } finally {
        commit("SET_IS_LOADING", -1);
      }
    },
  },
});
