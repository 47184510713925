<template>
    <div class="flexy pb-2">
        <div class="is-clearfix"></div>

        <loading-info />
        <div class="pt-4" style="margin-top: 10px">
            <FullCalendar
                :options="{
                    ...calendarOptions,
                    events: events,
                }"
            />
        </div>
    </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import roLocale from "@fullcalendar/core/locales/ro";
import listPlugin from "@fullcalendar/list";


import LoadingInfo from "@/components/LoadingInfo";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        FullCalendar, // make the <FullCalendar> tag available
        LoadingInfo,
    },
    computed: {
        ...mapState({
            events: (state) => state.events,
        }),
    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, listPlugin],
                initialView: "dayGridMonth",
                headerToolbar: {
                    center: "dayGridMonth,listYear", // buttons for switching between views
                },
                locale: roLocale,
                datesSet: this.handleMonthChange,
            },
        };
    },
    methods: {
        ...mapActions(["loadEvents"]),
        async handleMonthChange(ev) {
            await this.loadEvents({ start: ev.startStr, end: ev.endStr });
        },
    },
};
</script>
<style>
FullCalendar {
    max-width: 1100px;
    margin: 40px auto;
}

.fc-button-primary {
    background-color: #45b2d3 !important;
    border-color: #45b2d3 !important;
}


.fc-h-event .fc-event-main-frame {
    display: block; /* for make fc-event-title-container expand */
    }

.fc-event-title {
    margin: 1px;
    padding: 1px;
    white-space: normal;
}

.fc-event-time {
    margin: 1px;
    padding: 1px;
    white-space: normal;
    font-size: 0.9em;
    width: 3em;
}
</style>
