<template>
  <div>
    <div class="modal-card" v-show="!isLoading">
      <header class="modal-card-head">
        <p class="modal-card-title">Detalii service</p>
      </header>
      <section class="modal-card-body">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Service</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control is-expanded">
                <input
                  class="input"
                  type="text"
                  placeholder="revizie 15 000 sau schimb ulei"
                  v-model="service.name"
                  autofocus
                >
              </p>
              <p
                class="help is-danger"
                v-if="$v.service.name.$dirty && !$v.service.name.required"
              >Camp obligatoriu.</p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Autovehicul</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="service.vehicleId">
                    <option value>Alege autovehicul</option>
                    <option v-for="v in vehiclesList" :key="v.id" :value="v.id">{{v.name}} ({{v.totalKms | nbr}} km)</option>
                  </select>
                </div>
                <p
                  class="help is-danger"
                  v-if="$v.service.vehicleId.$dirty && !$v.service.vehicleId.required"
                >Alege autovehiculul</p>
              </div>
            </div>
          </div>
        </div>

<!-- 
      <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              Interval km
             
              </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="nr de km cand trebuie mers la service"
                  v-model="service.nextKms">
              </p>
            </div>
          </div>
        </div>

      <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              Interval luni
            
            </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="numeric"
                  placeholder="la cate luni trebuie mers la service"
                  v-model="service.notificationKms">
              </p>
            </div>
          </div>
        </div>

      <b>  Urmatoarea intrare service </b> -->

      <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              Km service
              <!-- <sup>
                  <b-tooltip label="Tooltip top" >
                    <b-icon is-rounded
                      class="has-background-black has-text-white is-rounded "
                      icon="mdi mdi-help"
                      size="is-small">
                  </b-icon>
                  </b-tooltip>
              </sup> -->
              </label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="nr de km pentru intrarea in service"
                  v-model="service.kms">
              </p>
              <p
                  class="help is-danger"
                  v-if="$v.service.kms.$dirty && !$v.service.kms.required"
                >Camp obligatoriu</p>
            </div>
          </div>
        </div>
        
      <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">
              Data service
              <!-- <sup>
                  <b-tooltip label="Tooltip top" >
                    <b-icon is-rounded
                      class="has-background-black has-text-white is-rounded "
                      icon="mdi mdi-help"
                      size="is-small">
                  </b-icon>
                  </b-tooltip>
              </sup> -->
            </label>
          </div>
          <div class="field-body">
           <b-field type="" :message='$v.service.date.$dirty && !$v.service.date.required == true ?"Camp obligatoriu." : "" ' expanded>
            <b-datepicker
              placeholder="data intrare in service"
              v-model="service.date"
              :date-formatter="dateFormatter"
              editable
              icon="calendar-today"
            >
            </b-datepicker>
          </b-field> 
        
          </div>
          
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Email alternativ</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <b-taginput
                  v-model="service.otherEmails"
                  :before-adding="beforeAdding"
                  placeholder="adrese de email alternative pentru notificari"
                ></b-taginput>
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Observatii</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <textarea
                  class="textarea"
                  v-model="service.comments"
                  placeholder="informatii suplimentare"
                ></textarea>
              </p>
            </div>
          </div>
        </div>
          <p class="help is-info has-text-centered">Iti vom trimite un email cu 500 de km inainte de atingerea nr. de km sau 7 zile  inainte de data pentru service.</p>


        <!-- 
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Carburant</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="vehicle.fuelTypeId">                    
                    <option
                      v-for="ft in fuelTypes"
                      :selected="ft.id == vehicle.fuelTypeId"
                      :key="ft.id"
                      :value="ft.id"
                    >{{ft.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Model</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Nissan Qashqai"
                  v-model="vehicle.model"
                >
              </p>
            </div>
          </div>
        </div>
       
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">ITP</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <b-datepicker
                  placeholder="Data expirare ITP"
                  v-model="vehicle.itp"
                  :date-formatter="dateFormatter"
                  editable
                  icon="calendar-today"
                ></b-datepicker>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">RCA</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <b-datepicker
                  placeholder="Data expirare RCA"
                  v-model="vehicle.rca"
                  :date-formatter="dateFormatter"
                  icon="calendar-today"
                ></b-datepicker>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Observatii</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <textarea
                  class="textarea"
                  placeholder="informatii suplimentare"
                  v-model="vehicle.comments"
                ></textarea>
              </p>
            </div>
          </div>
        </div>
        -->
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Renunta</button>
        <button class="button is-primary" :class="{'is-loading':isSaving}" @click.prevent="submit">
          <span class="icon is-small">
            <i class="fa fa-save"></i>
          </span>
          <span>Salveaza</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import LoadingInfo from "@/components/LoadingInfo";

export default {
  name: "ServiceForm",
  props: ["serviceId", "reload"],
  components: { LoadingInfo },
  computed: {
    ...mapGetters(["vehiclesList", "isLoading"])
  },
  data: function() {
    return {
      isSaving: false,
      service: {
        id: null,
        date:null,  
        kms:null,      
        name: "",
        vehicleId: "",
        otherEmails: [],
        comments: ""
      }
    };
  },
  validations: {
    service: {
      name: { required },
      vehicleId: { required },
      kms: { required },
      date: { required },
    }
  },
  methods: {
    ...mapActions(["loadInitialWithPromise",'saveService','loadService']),
    dateFormatter(dt) {
      return dt.toLocaleDateString("ro-RO");
    },
    beforeAdding(email) {
      var reg = /\S+@\S+\.\S+/;
      return reg.test(email);
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSaving = !this.isSaving;
        this.saveService(this.service)
          .then(resp => {
            if (this.reload)
              this.reload();
            this.$parent.close();
            this.isSaving = !this.isSaving;            
          })
          .catch(err => {
            this.isSaving = !this.isSaving;
            console.error(err);
          });
      }
    },
    load(id) {
      this.loadService(id)
        .then(resp => {
          if (resp.data.date) resp.data.date = new Date(resp.data.date);
          this.service = resp.data;
        })
        .catch(err => console.log("error loading veh:", err));
    }
  },
  mounted() {
    this.loadInitialWithPromise().then(() => {
      var id = this.serviceId;
      if (id > 0) {
        this.load(id);
      }
    });
  }
};
</script>


<style lang="scss"  >

@import "~bulma/sass/utilities/_all";
@import '../../_variables';

.datepicker + .help{
  color: $danger ;
}
</style>


